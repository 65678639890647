
//ORDEM DE CARGA-------------------------------------------------------------------------



export const ORDEMCARGA_LIST_DEVOLUCAO = "ORDEMCARGA_LIST_DEVOLUCAO";
export const ORDEMCARGA_QTDE_DEVOLUCAO_UPDATE = "ORDEMCARGA_QTDE_DEVOLUCAO_UPDATE";

export const ORDEMCARGA_LOADER_ISUPDATING_DEVOLUCAO = "ORDEMCARGA_LOADER_ISUPDATING_DEVOLUCAO";
export const ORDEMCARGA_REMOVEITEM_DEVOLUCAO = "ORDEMCARGA_REMOVEITEM_DEVOLUCAO";
export const ORDEMCARGA_REMOVE_DEVOLUCAO = "ORDEMCARGA_REMOVE_DEVOLUCAO";
export const ORDEMCARGA_QTDE_DEV_UPDATE = "ORDEMCARGA_QTDE_DEV_UPDATE";
export const ORDEMCARGA_LIST_VENDEDORES = "ORDEMCARGA_LIST_VENDEDORES";
export const ORDEMCARGA_CHECK_VENDEDORES = "ORDEMCARGA_CHECK_VENDEDORES";


//DEVOLUÇÃO--------------------------------------------------------------------------------------------
export const ORDEMCARGA_LOADER_ISUPDATING_NAOENTREGUE = "ORDEMCARGA_LOADER_ISUPDATING_NAOENTREGUE";
export const ORDEMCARGA_REMOVE_NAOENTREGUE = "ORDEMCARGA_REMOVE_NAOENTREGUE";
export const ORDEMCARGA_REMOVEITEM_NAOENTREGUE = "ORDEMCARGA_REMOVEITEM_NAOENTREGUE";
export const ORDEMCARGA_QTDE_NAOENTREGUE_UPDATE = "ORDEMCARGA_QTDE_NAOENTREGUE_UPDATE";

export const ORDEMCARGA_LOADER_ISUPDATING_DEVTRANSP = "ORDEMCARGA_LOADER_ISUPDATING_DEVTRANSP";
export const ORDEMCARGA_REMOVE_DEVTRANSP = "ORDEMCARGA_REMOVE_DEVTRANSP";
export const ORDEMCARGA_REMOVEITEM_DEVTRANSP = "ORDEMCARGA_REMOVEITEM_DEVTRANSP";
export const ORDEMCARGA_QTDE_DEVTRANSP_UPDATE = "ORDEMCARGA_QTDE_DEVTRANSP_UPDATE";

export const ORDEMCARGA_LOADER_ISUPDATING_DEVENTREGUE = "ORDEMCARGA_LOADER_ISUPDATING_DEVENTREGUE";
export const ORDEMCARGA_REMOVE_DEVENTREGUE = "ORDEMCARGA_REMOVE_DEVENTREGUE";
export const ORDEMCARGA_REMOVEITEM_DEVENTREGUE = "ORDEMCARGA_REMOVEITEM_DEVENTREGUE";
export const ORDEMCARGA_QTDE_DEVENTREGUE_UPDATE = "ORDEMCARGA_QTDE_DEVENTREGUE_UPDATE";

export const ORDEMCARGA_PEDIDOMODALDEV_UPDATE = "ORDEMCARGA_PEDIDOMODALDEV_UPDATE";
export const ORDEMCARGA_LOADER_DEVOLUCAO = "ORDEMCARGA_LOADER_DEVOLUCAO";
//-----------------------------------------------------------------------------------------------------


export const ORDEMCARGA_LIST = "ORDEMCARGA_LIST";
export const ORDEMCARGA_LIST_NAOENTREGUE = "ORDEMCARGA_LIST_NAOENTREGUE";
export const ORDEMCARGA_LIST_ENTREGUE = "ORDEMCARGA_LIST_ENTREGUE";

export const ORDEMCARGA_QTDE_NAOENTREGUE = "ORDEMCARGA_QTDE_NAOENTREGUE";
export const ORDEMCARGA_QTDE_ENTREGUE = "ORDEMCARGA_QTDE_ENTREGUE";
export const ORDEMCARGA_CHKNAOENTREGUE_UPDATE = "ORDEMCARGA_CHKNAOENTREGUE_UPDATE";
export const ORDEMCARGA_CHKNAOENTREGUE_CLEAR = "ORDEMCARGA_CHKNAOENTREGUE_CLEAR";
export const ORDEMCARGA_LOADER_NAOENTREGUE = "ORDEMCARGA_LOADER_NAOENTREGUE";
export const ORDEMCARGA_LOADER_ENTREGUE = "ORDEMCARGA_LOADER_ENTREGUE";
export const ORDEMCARGA_LIST_MOTIVOS = "ORDEMCARGA_LIST_MOTIVOS";

export const ORDEMCARGA_CHKNAOENTREGUE_REMOVE = "ORDEMCARGA_CHKNAOENTREGUE_REMOVE";
export const ORDEMCARGA_CHKNAOENTREGUE_INSERT = "ORDEMCARGA_CHKNAOENTREGUE_INSERT";
export const ORDEMCARGA_LOG_INSERT = "ORDEMCARGA_LOG_INSERT";

export const ORDEMCARGA_PEDIDOMODAL_UPDATE = "ORDEMCARGA_PEDIDOMODAL_UPDATE";


//EM ANDAMENTO
export const ORDEMCARGA_UPDATE_FRETE_PEDIDOSPAGOSNAOFATURADOS = "ORDEMCARGA_UPDATE_FRETE_PEDIDOSPAGOSNAOFATURADOS";
export const ORDEMCARGA_ISSAVING_FRETE_PEDIDOSPAGOSNAOFATURADOS = "ORDEMCARGA_ISSAVING_FRETE_PEDIDOSPAGOSNAOFATURADOS";
export const ORDEMCARGA_ISEDITING_PEDIDOSPAGOSNAOFATURADOS = "ORDEMCARGA_ISEDITING_PEDIDOSPAGOSNAOFATURADOS";
export const ORDEMCARGA_EMANDAMENTO_ISENTREGASAVING = "ORDEMCARGA_EMANDAMENTO_ISENTREGASAVING";
export const ORDEMCARGA_EMANDAMENTO_REMOVE = "ORDEMCARGA_EMANDAMENTO_REMOVE";
export const ORDEMCARGA_LIST_PEDIDOSPAGOSNAOFATURADOS = "ORDEMCARGA_LIST_PEDIDOSPAGOSNAOFATURADOS";
export const ORDEMCARGA_QTDE_PEDIDOSPAGOSNAOFATURADOS = "ORDEMCARGA_QTDE_PEDIDOSPAGOSNAOFATURADOS";
export const ORDEMCARGA_LOADER_PEDIDOSPAGOSNAOFATURADOS = "ORDEMCARGA_LOADER_PEDIDOSPAGOSNAOFATURADOS";
export const ORDEMCARGA_LOADER_PEDIDOSEMANDAMENTO_LIGAR = "ORDEMCARGA_LOADER_PEDIDOSEMANDAMENTO_LIGAR";
export const ORDEMCARGA_COMPLEMENTO_PEDIDOSEMANDAMENTO_LIGAR = "ORDEMCARGA_COMPLEMENTO_PEDIDOSEMANDAMENTO_LIGAR";
export const ORDEMCARGA_MODAL_PEDIDOSEMANDAMENTO_LIGAR = "ORDEMCARGA_MODAL_PEDIDOSEMANDAMENTO_LIGAR";






//---------------------------------------------------------------------------------------


//PEDIDOS----------------------------------------------------------------------------------------------
export const ORDEMCARGA_LIST_PEDIDOSNAOPADOS = "ORDEMCARGA_LIST_PEDIDOSNAOPADOS";
export const ORDEMCARGA_LIST_PEDIDOSFATURADOSSEMROTA = "ORDEMCARGA_LIST_PEDIDOSFATURADOSSEMROTA";
export const ORDEMCARGA_QTDE_PEDIDOSNAOPADOS = "ORDEMCARGA_QTDE_PEDIDOSNAOPADOS";
export const ORDEMCARGA_LOADER_PEDIDOSNAOPADOS_LIGAR = "ORDEMCARGA_LOADER_PEDIDOSNAOPADOS_LIGAR";
export const ORDEMCARGA_COMPLEMENTO_PEDIDOSNAOPADOS_LIGAR = "ORDEMCARGA_COMPLEMENTO_PEDIDOSNAOPADOS_LIGAR";
export const ORDEMCARGA_MODAL_PEDIDOSNAOPADOS_LIGAR = "ORDEMCARGA_MODAL_PEDIDOSNAOPADOS_LIGAR";



export const ORDEMCARGA_QTDE_PEDIDOSFATURADOSSEMROTA = "ORDEMCARGA_QTDE_PEDIDOSFATURADOSSEMROTA";
export const ORDEMCARGA_LOADER_PEDIDOSNAOPADOS = "ORDEMCARGA_LOADER_PEDIDOSNAOPADOS";
export const ORDEMCARGA_LOADER_PEDIDOSFATURADOSSEMROTA = "ORDEMCARGA_LOADER_PEDIDOSFATURADOSSEMROTA";
//------------------------------------------------------------------------------------------------------


//LOGIN-------------------------------------------------------------------------------------------------
export const LOGIN_POST = "LOGIN_POST";
export const LOGIN_MENU_POST = "LOGIN_MENU_POST";
export const LOGIN_REDIRECT = "LOGIN_REDIRECT";
//------------------------------------------------------------------------------------------------------


//EM TRANSPORTADORA-------------------------------------------------------------------------------------
export const ORDEMCARGA_LIST_EMTRANSPORTADORA = "ORDEMCARGA_LIST_EMTRANSPORTADORA";
export const ORDEMCARGA_QTDE_EMTRANSPORTADORA = "ORDEMCARGA_QTDE_EMTRANSPORTADORA";
export const ORDEMCARGA_CHKEMTRANSPORTADORA_REMOVE = "ORDEMCARGA_CHKEMTRANSPORTADORA_REMOVE";
export const ORDEMCARGA_CHKEMTRANSPORTADORA_INSERT = "ORDEMCARGA_CHKEMTRANSPORTADORA_INSERT";
export const ORDEMCARGA_CHKEMTRANSPORTADORA_UPDATE = "ORDEMCARGA_CHKEMTRANSPORTADORA_UPDATE";
export const ORDEMCARGA_CHKEMTRANSPORTADORA_CLEAR = "ORDEMCARGA_CHKEMTRANSPORTADORA_CLEAR";
export const ORDEMCARGA_LOADER_EMTRANSPORTADORA = "ORDEMCARGA_LOADER_EMTRANSPORTADORA";
export const ORDEMCARGA_LOGEMTRANSPORTADORA_INSERT = "ORDEMCARGA_LOGEMTRANSPORTADORA_INSERT";
export const ORDEMCARGA_EMTRANSPORTADORAMODAL_UPDATE = "ORDEMCARGA_EMTRANSPORTADORAMODAL_UPDATE";
//------------------------------------------------------------------------------------------------------


//A SAIR------------------------------------------------------------------------------------------------
export const ORDEMCARGA_LIST_ASAIR = "ORDEMCARGA_LIST_ASAIR";
export const ORDEMCARGA_QTDE_ASAIR = "ORDEMCARGA_QTDE_ASAIR";
export const ORDEMCARGA_LOADER_ASAIR = "ORDEMCARGA_LOADER_ASAIR";
export const ORDEMCARGA_ASAIR_CHKEMROTA_INSERT = "ORDEMCARGA_ASAIR_CHKEMROTA_INSERT";
export const ORDEMCARGA_ASAIR_CHKEMROTA_REMOVE = "ORDEMCARGA_ASAIR_CHKEMROTA_REMOVE";
export const ORDEMCARGA_ASAIR_CHKEMROTA_UPDATE = "ORDEMCARGA_ASAIR_CHKEMROTA_UPDATE";
export const ORDEMCARGA_ASAIR_CHKEMROTA_CLEAR = "ORDEMCARGA_ASAIR_CHKEMROTA_CLEAR";

//frete A SAIR
export const ORDEMCARGA_ASAIR_FRETEOPENCLOSE_UPDATE = "ORDEMCARGA_ASAIR_FRETEOPENCLOSE_UPDATE";
export const ORDEMCARGA_ASAIR_FRETECHECK_UPDATE = "ORDEMCARGA_ASAIR_FRETECHECK_UPDATE";
export const ORDEMCARGA_ASAIR_FRETEISSAVING_UPDATE = "ORDEMCARGA_ASAIR_FRETEISSAVING_UPDATE";

//FRETE EM ROTA
export const ORDEMCARGA_EMROTA_FRETEOPENCLOSE_UPDATE = "ORDEMCARGA_EMROTA_FRETEOPENCLOSE_UPDATE";
export const ORDEMCARGA_EMROTA_FRETECHECK_UPDATE = "ORDEMCARGA_EMROTA_FRETECHECK_UPDATE";
export const ORDEMCARGA_EMROTA_FRETEISSAVING_UPDATE = "ORDEMCARGA_EMROTA_FRETEISSAVING_UPDATE";
export const ORDEMCARGA_EMROTA_ASAIR_ISUPDATING = "ORDEMCARGA_EMROTA_ASAIR_ISUPDATING";

//PRAZO EM ROTA
export const ORDEMCARGA_EMROTA_CHANGE_GERAL = "ORDEMCARGA_EMROTA_CHANGE_GERAL";
export const ORDEMCARGA_EMTRANSPORTADORA_CHANGE_GERAL = "ORDEMCARGA_EMTRANSPORTADORA_CHANGE_GERAL";
export const ORDEMCARGA_DEVOLUCAO_CHANGE_GERAL = "ORDEMCARGA_DEVOLUCAO_CHANGE_GERAL";

export const ORDEMCARGA_EMROTA_LIST_APPENTREGA = "ORDEMCARGA_EMROTA_LIST_APPENTREGA";
export const ORDEMCARGA_EMROTA_LOADER_APPENTREGA = "ORDEMCARGA_EMROTA_LOADER_APPENTREGA";
export const ORDEMCARGA_EMROTA_MODAL_APPENTREGA = "ORDEMCARGA_EMROTA_MODAL_APPENTREGA";



//FRETE EM TRANSPORTADORA
export const ORDEMCARGA_EMTRANSPORTADORA_FRETEOPENCLOSE_UPDATE = "ORDEMCARGA_EMTRANSPORTADORA_FRETEOPENCLOSE_UPDATE"
export const ORDEMCARGA_EMTRANSPORTADORA_FRETECHECK_UPDATE = "ORDEMCARGA_EMTRANSPORTADORA_FRETECHECK_UPDATE"
export const ORDEMCARGA_EMTRANSPORTADORA_FRETEISSAVING_UPDATE = "ORDEMCARGA_EMTRANSPORTADORA_FRETEISSAVING_UPDATE"
//------------------------------------------------------------------------------------------------------


// A SAIR MOTIVO N SAIU
export const ORDEMCARGA_PEDIDOMODALNSAIU_UPDATE = "ORDEMCARGA_PEDIDOMODALNSAIU_UPDATE"

export const ORDEMCARGA_QTDE_ASAIR_DEVOLUCAO = "ORDEMCARGA_QTDE_ASAIR_DEVOLUCAO"
export const ORDEMCARGA_LOADER_ASAIR_DEVOLUCAO_ISUPDATING = "ORDEMCARGA_LOADER_ASAIR_DEVOLUCAO_ISUPDATING"
export const ORDEMCARGA_REMOVE_ASAIR_DEVOLUCAO = "ORDEMCARGA_REMOVE_ASAIR_DEVOLUCAO"
export const ORDEMCARGA_REMOVEITEM_ASAIR_DEVOLUCAO = "ORDEMCARGA_REMOVEITEM_ASAIR_DEVOLUCAO"



//ROBÔ DE PREÇOS----------------------------------------------------------------------------------------
export const ROBODEPRECOS_LIST_CONFIGURACOES = "ROBODEPRECOS_LIST_CONFIGURACOES"
export const ROBODEPRECOS_QTDE_CONFIGURACOES = "ROBODEPRECOS_QTDE_CONFIGURACOES"
export const ROBODEPRECOS_LOADER_CONFIGURACOES = "ROBODEPRECOS_LOADER_CONFIGURACOES"
export const ROBODEPRECOS_LIST_MODIFICADOS = "ROBODEPRECOS_LIST_MODIFICADOS"
export const ROBODEPRECOS_QTDE_MODIFICADOS = "ROBODEPRECOS_QTDE_MODIFICADOS"
export const ROBODEPRECOS_LOADER_MODIFICADOS = "ROBODEPRECOS_LOADER_MODIFICADOS"

export const ROBODEPRECOS_LIST_FORADOSLIMITES = "ROBODEPRECOS_LIST_FORADOSLIMITES"
export const ROBODEPRECOS_QTDE_FORADOSLIMITES = "ROBODEPRECOS_QTDE_FORADOSLIMITES"
export const ROBODEPRECOS_LOADER_FORADOSLIMITES = "ROBODEPRECOS_LOADER_FORADOSLIMITES"

export const ROBODEPRECOS_LIST_MANTIDOS = "ROBODEPRECOS_LIST_MANTIDOS"
export const ROBODEPRECOS_QTDE_MANTIDOS = "ROBODEPRECOS_QTDE_MANTIDOS"
export const ROBODEPRECOS_LOADER_MANTIDOS = "ROBODEPRECOS_LOADER_MANTIDOS"
export const ROBODEPRECOS_LIST_MONITORADOS = "ROBODEPRECOS_LIST_MONITORADOS"
export const ROBODEPRECOS_QTDE_MONITORADOS = "ROBODEPRECOS_QTDE_MONITORADOS"
export const ROBODEPRECOS_LOADER_MONITORADOS = "ROBODEPRECOS_LOADER_MONITORADOS"

export const ROBODEPRECOS_CHANGE_CONFIGURACOES = "ROBODEPRECOS_CHANGE_CONFIGURACOES"
export const ROBODEPRECOS_CLEAR_CONFIGURACOES = "ROBODEPRECOS_CLEAR_CONFIGURACOES"
export const ROBODEPRECOS_UPDATE_CONFIGURACOES = "ROBODEPRECOS_UPDATE_CONFIGURACOES"

export const ROBODEPRECOS_LIST_STATUS = "ROBODEPRECOS_LIST_STATUS"


//------------------------------------------------------------------------------------------------------


//GRAFICO DE VENDAS-------------------------------------------------------------------------------------
export const GRAFICODEVENDAS_LIST = "GRAFICODEVENDAS_LIST"
export const GRAFICODEVENDAS_LOADER = "GRAFICODEVENDAS_LOADER"
export const GRAFICODEVENDAS_POPUP_FATURAR_LOADER = "GRAFICODEVENDAS_POPUP_FATURAR_LOADER"
export const GRAFICODEVENDAS_POPUP_FATURAR = "GRAFICODEVENDAS_POPUP_FATURAR"
export const GRAFICODEVENDAS_POPUP_PAGAR_LOADER = "GRAFICODEVENDAS_POPUP_PAGAR_LOADER"
export const GRAFICODEVENDAS_POPUP_PAGAR = "GRAFICODEVENDAS_POPUP_PAGAR"
//------------------------------------------------------------------------------------------------------


//PESQUISA DE SATISFAÇÃO--------------------------------------------------------------------------------
export const PESQUISA_LIST = "PESQUISA_LIST"
export const PESQUISA_LOADER = "PESQUISA_LOADER"
export const PESQUISA_CHANGE = "PESQUISA_CHANGE"
export const PESQUISA_CHANGE_STATUS = "PESQUISA_CHANGE_STATUS"
//------------------------------------------------------------------------------------------------------



//DESPESAS DE VIAGENS--------------------------------------------------------------------------------
export const DESPESASDEVIAGENS_LIST_SAIDAS = "DESPESASDEVIAGENS_LIST_SAIDAS"
export const DESPESASDEVIAGENS_LIST_AJUDANTE = "DESPESASDEVIAGENS_LIST_AJUDANTE"
export const DESPESASDEVIAGENS_LIST_MOTORISTAS = "DESPESASDEVIAGENS_LIST_MOTORISTAS"
export const DESPESASDEVIAGENS_LIST_AJUDANTES = "DESPESASDEVIAGENS_LIST_AJUDANTES"
export const DESPESASDEVIAGENS_LOADER_SAIDAS = "DESPESASDEVIAGENS_LOADER_SAIDAS"

export const DESPESASDEVIAGENS_CHANGE_SAIDAS_MOTORISTA = "DESPESASDEVIAGENS_CHANGE_SAIDAS_MOTORISTA"
export const DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEUM = "DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEUM"
export const DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEDOIS = "DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEDOIS"
export const DESPESASDEVIAGENS_CHANGE_SAIDAS_DTSAIDA = "DESPESASDEVIAGENS_CHANGE_SAIDAS_DTPREVISAO"
export const DESPESASDEVIAGENS_CHANGE_SAIDAS_ALTEROU = "DESPESASDEVIAGENS_CHANGE_SAIDAS_ALTEROU"
export const DESPESASDEVIAGENS_CHANGE_SAIDAS_REMOVE = "DESPESASDEVIAGENS_CHANGE_SAIDAS_REMOVE"
export const DESPESASDEVIAGENS_CHANGE_SAIDAS_SAVING = "DESPESASDEVIAGENS_CHANGE_SAIDAS_SAVING"


export const DESPESASDEVIAGENS_LIST_CHEGADAS = "DESPESASDEVIAGENS_LIST_CHEGADAS"
export const DESPESASDEVIAGENS_LOADER_CHEGADAS = "DESPESASDEVIAGENS_LOADER_CHEGADAS"
export const DESPESASDEVIAGENS_CHANGE_CHEGADAS_DTCHEGADA = "DESPESASDEVIAGENS_CHANGE_CHEGADAS_DTCHEGADA"
export const DESPESASDEVIAGENS_LIST_VIAGEMDESPESAS = "DESPESASDEVIAGENS_LIST_VIAGEMDESPESAS"
export const DESPESASDEVIAGENS_CHANGE_CHEGADAS = "DESPESASDEVIAGENS_CHANGE_CHEGADAS"
export const DESPESASDEVIAGENS_LIST_ADICIONAL = "DESPESASDEVIAGENS_LIST_ADICIONAL"

export const DESPESASDEVIAGENS_INSERT_CHEGADAS = "DESPESASDEVIAGENS_INSERT_CHEGADAS"
export const DESPESASDEVIAGENS_ADICIONAL_INSERT_CHEGADAS = "DESPESASDEVIAGENS_ADICIONAL_INSERT_CHEGADAS"

export const DESPESASDEVIAGENS_CHANGE_CHEGADAS_SAVING = "DESPESASDEVIAGENS_CHANGE_CHEGADAS_SAVING"
export const DESPESASDEVIAGENS_CHANGE_CHEGADAS_REMOVE = "DESPESASDEVIAGENS_CHANGE_CHEGADAS_REMOVE"
export const DESPESASDEVIAGENS_LIST_FERIADOS = "DESPESASDEVIAGENS_LIST_FERIADOS"

export const DESPESASDEVIAGENS_LIST_RECIBOS = "DESPESASDEVIAGENS_LIST_RECIBOS"
export const DESPESASDEVIAGENS_LOADER_RECIBOS = "DESPESASDEVIAGENS_LOADER_RECIBOS"
export const DESPESASDEVIAGENS_CHANGEDT_RECIBOS = "DESPESASDEVIAGENS_CHANGEDT_RECIBOS"


export const DESPESASDEVIAGENS_LIST_ASSINATURAS = "DESPESASDEVIAGENS_LIST_ASSINATURAS"
export const DESPESASDEVIAGENS_LOADER_ASSINATURAS = "DESPESASDEVIAGENS_LOADER_ASSINATURAS"
export const DESPESASDEVIAGENS_CHANGEDT_ASSINATURAS = "DESPESASDEVIAGENS_CHANGEDT_ASSINATURAS"
export const DESPESASDEVIAGENS_LIST_ASSINATURAS_RH = "DESPESASDEVIAGENS_LIST_ASSINATURAS_RH"

export const DESPESASDEVIAGENS_LIST_FINANCEIROAPROCESSAR = "DESPESASDEVIAGENS_LIST_FINANCEIROAPROCESSAR"
export const DESPESASDEVIAGENS_LOADER_FINANCEIROAPROCESSAR = "DESPESASDEVIAGENS_LOADER_FINANCEIROAPROCESSAR"

export const DESPESASDEVIAGENS_LIST_FINANCEIROGERADO = "DESPESASDEVIAGENS_LIST_FINANCEIROGERADO"
export const DESPESASDEVIAGENS_LOADER_FINANCEIROGERADO = "DESPESASDEVIAGENS_LOADER_FINANCEIROGERADO"
export const DESPESASDEVIAGENS_LIST_FINANCEIROGERADO_USERSFIN = "DESPESASDEVIAGENS_LIST_FINANCEIROGERADO_USERSFIN"
export const DESPESASDEVIAGENS_CHANGE_FINANCEIROGERADO_SAVING = "DESPESASDEVIAGENS_CHANGE_FINANCEIROGERADO_SAVING"


export const DESPESASDEVIAGENS_SAVING_AJUDANTE = "DESPESASDEVIAGENS_SAVING_AJUDANTE"
export const DESPESASDEVIAGENS_EDITING_AJUDANTE = "DESPESASDEVIAGENS_EDITING_AJUDANTE"
export const DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_NOME = "DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_NOME"
export const DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_ATIVO = "DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_ATIVO"
export const DESPESASDEVIAGENS_CHANGE_AJUDANTE_SAVING = "DESPESASDEVIAGENS_CHANGE_AJUDANTE_SAVING"




export const DESPESASDEVIAGENS_REMOVE_FINANCEIROGERADO = "DESPESASDEVIAGENS_REMOVE_FINANCEIROGERADO"
export const DESPESASDEVIAGENS_REMOVE_FINANCEIROGERADO_OC = "DESPESASDEVIAGENS_REMOVE_FINANCEIROGERADO_OC"

//cancel ordem de carga
export const DESPESASDEVIAGENS_CHANGE_ISCANCELING = "DESPESASDEVIAGENS_CHANGE_ISCANCELING"
export const DESPESASDEVIAGENS_REMOVE_ORDEMCARGA = "DESPESASDEVIAGENS_REMOVE_ORDEMCARGA"

export const DESPESASDEVIAGENS_UPDATE_BUSCA = "DESPESASDEVIAGENS_UPDATE_BUSCA"

export const DESPESASDEVIAGENS_VALIDACAO_BUSCA_ADD = "DESPESASDEVIAGENS_VALIDACAO_BUSCA_ADD"
export const DESPESASDEVIAGENS_VALIDACAO_BUSCA_REMOVE = "DESPESASDEVIAGENS_VALIDACAO_BUSCA_REMOVE"
export const DESPESASDEVIAGENS_ISSEARCHING_BUSCA = "DESPESASDEVIAGENS_ISSEARCHING_BUSCA"
export const DESPESASDEVIAGENS_LIST_SEARCH = "DESPESASDEVIAGENS_LIST_SEARCH"

export const DESPESASDEVIAGENS_LIST_MESMODIA = "DESPESASDEVIAGENS_LIST_MESMODIA"
export const DESPESASDEVIAGENS_ISREMOVING_MESMODIA = "DESPESASDEVIAGENS_ISREMOVING_MESMODIA"
export const DESPESASDEVIAGENS_REMOVE_MESMODIA = "DESPESASDEVIAGENS_REMOVE_MESMODIA"





//CONSULTORES-------------------------------------------------------------------------------------------
export const CONSULTORES_LIST_FECHAMENTO = "CONSULTORES_LIST_FECHAMENTO"
export const CONSULTORES_LOADER_FECHAMENTO = "CONSULTORES_LOADER_FECHAMENTO"
export const CONSULTORES_LIST_FECHAMENTO90 = "CONSULTORES_LIST_FECHAMENTO90"
export const CONSULTORES_LOADER_FECHAMENTO90 = "CONSULTORES_LOADER_FECHAMENTO90"
export const CONSULTORES_UPDATE_BUSCA = "CONSULTORES_UPDATE_BUSCA"

export const CONSULTORES_VALIDACAO_BUSCA_ADD = "CONSULTORES_VALIDACAO_BUSCA_ADD"
export const CONSULTORES_VALIDACAO_BUSCA_REMOVE = "CONSULTORES_VALIDACAO_BUSCA_REMOVE"
export const CONSULTORES_ISSEARCHING_BUSCA = "CONSULTORES_ISSEARCHING_BUSCA"

export const CONSULTORES_LIST = "CONSULTORES_LIST"
export const CONSULTORES_LIST_SEARCH = "CONSULTORES_LIST_SEARCH"
export const CONSULTORES_LIST_CIDADES = "CONSULTORES_LIST_CIDADES"
//------------------------------------------------------------------------------------------------------



//RENEGOCIACAO DE DÍVIDAS-------------------------------------------------------------------------------
export const RENEGOCIACAO_LIST_CONFIGURACOES = "RENEGOCIACAO_LIST_CONFIGURACOES"
export const RENEGOCIACAO_LIST_DIVIDAS = "RENEGOCIACAO_LIST_DIVIDAS"
export const RENEGOCIACAO_LOADER_DIVIDAS = "RENEGOCIACAO_LOADER_DIVIDAS"
export const RENEGOCIACAO_CHECK_ITEM = "RENEGOCIACAO_CHECK_ITEM"
export const RENEGOCIACAO_CHECK_ALL = "RENEGOCIACAO_CHECK_ALL"
export const RENEGOCIACAO_UPDATE_CONFIG = "RENEGOCIACAO_UPDATE_CONFIG"
export const RENEGOCIACAO_LOADER_ISSAVING = "RENEGOCIACAO_LOADER_ISSAVING"
export const RENEGOCIACAO_LOADER_CONFIG = "RENEGOCIACAO_LOADER_CONFIG"
export const RENEGOCIACAO_LIST_CONSULTA = "RENEGOCIACAO_LIST_CONSULTA"
export const RENEGOCIACAO_LOADER_CONSULTA = "RENEGOCIACAO_LOADER_CONSULTA"
export const RENEGOCIACAO_LOADER_ISSIMULATING = "RENEGOCIACAO_LOADER_ISSIMULATING"

export const RENEGOCIACAO_LIST_CREDITO = "RENEGOCIACAO_LIST_CREDITO"
export const RENEGOCIACAO_LIST_CREDITODADOS = "RENEGOCIACAO_LIST_CREDITODADOS"
export const RENEGOCIACAO_ADD_PARCEIRO = "RENEGOCIACAO_ADD_PARCEIRO"

export const RENEGOCIACAO_LIST_COMPENSDADOS = "RENEGOCIACAO_LIST_COMPENSDADOS"
export const RENEGOCIACAO_LIST_DIVIDASDADOS = "RENEGOCIACAO_LIST_DIVIDASDADOS"

export const RENEGOCIACAO_CANCEL_CONSULTA = "RENEGOCIACAO_CANCEL_CONSULTA"
export const RENEGOCIACAO_ISCANCELING = "RENEGOCIACAO_ISCANCELING"
//------------------------------------------------------------------------------------------------------



//MARKETPLACES------------------------------------------------------------------------------------------

export const MARKETPLACES_CHANGE_CAMPOS = "MARKETPLACES_CHANGE_CAMPOS";
export const MARKETPLACES_CONCI_LIST_IMPORTACOES = "MARKETPLACES_CONCI_LIST_IMPORTACOES";
export const MARKETPLACES_CONCI_LOADER_IMPORTACOES = "MARKETPLACES_CONCI_LOADER_IMPORTACOES";
export const MARKETPLACES_CONCI_ISIMPORTING = "MARKETPLACES_CONCI_ISIMPORTING";
export const MARKETPLACES_CONCI_IMPORT = "MARKETPLACES_CONCI_IMPORT";
export const MARKETPLACES_CONCI_ISPROCESSING = "MARKETPLACES_CONCI_ISPROCESSING";
export const MARKETPLACES_CONCI_ISLISTINGREPASSE = "MARKETPLACES_CONCI_ISLISTINGREPASSE";
export const MARKETPLACES_CONCI_LIST_REPASSE = "MARKETPLACES_CONCI_LIST_REPASSE";
export const MARKETPLACES_CONCI_IMPORT_CHANGE = "MARKETPLACES_CONCI_IMPORT_CHANGE";




export const MARKETPLACES_LIST = "MARKETPLACES_LIST";
export const MARKETPLACES_UPDATE_PARAMETROS = "MARKETPLACES_UPDATE_PARAMETROS";
export const MARKETPLACES_ISSEARCHING = "MARKETPLACES_ISSEARCHING";

export const MARKETPLACES_PARCEIRO_CODPARC = "MARKETPLACES_PARCEIRO_CODPARC";
export const MARKETPLACES_PARCEIRO_DADOS = "MARKETPLACES_PARCEIRO_DADOS";
export const MARKETPLACES_PARCEIRO_NOVACIDADE = "MARKETPLACES_PARCEIRO_NOVACIDADE";
export const MARKETPLACES_PARCEIRO_ISSAVING = "MARKETPLACES_PARCEIRO_ISSAVING";
export const MARKETPLACES_PARCEIRO_ISSEARCHING = "MARKETPLACES_PARCEIRO_ISSEARCHING";
//------------------------------------------------------------------------------------------------------


export const COMPARATIVOPRODUTOS_LIST = "COMPARATIVOPRODUTOS_LIST";
export const COMPARATIVOPRODUTOS_ISSEARCHING = "COMPARATIVOPRODUTOS_ISSEARCHING";
export const COMPARATIVOPRODUTOS_LIST_COMBO = "COMPARATIVOPRODUTOS_LIST_COMBO";
export const COMPARATIVOPRODUTOS_CHANGE = "COMPARATIVOPRODUTOS_CHANGE";


export const COMISSOES_LIST = "COMISSOES_LIST";
export const COMISSOES_ISLOADING = "COMISSOES_ISLOADING";
export const COMISSOES_VENDEDORES_LIST = "COMISSOES_VENDEDORES_LIST";
export const COMISSOES_CHANGE_VENDEDOR = "COMISSOES_CHANGE_VENDEDOR";
export const COMISSOES_PEDIDO_ISSAVING = "COMISSOES_PEDIDO_ISSAVING";
export const COMISSOES_PEDIDO_UPDATE = "COMISSOES_PEDIDO_UPDATE";
export const COMISSOES_RELATORIO_LIST = "COMISSOES_RELATORIO_LIST";
export const COMISSOES_UPDATE_TICKET = "COMISSOES_UPDATE_TICKET";


export const CADASTRODEPRODUTOS_EXPORT_LIST = "CADASTRODEPRODUTOS_EXPORT_LIST";
export const CADASTRODEPRODUTOS_EXPORT_ISEXPORTING = "CADASTRODEPRODUTOS_EXPORT_ISEXPORTING";
export const CADASTRODEPRODUTOS_EXPORT_LEROY = "CADASTRODEPRODUTOS_EXPORT_EXPORTING";
export const CADASTRODEPRODUTOS_EXPORT_ISLEROYEXPORTING = "CADASTRODEPRODUTOS_EXPORT_ISLEROYEXPORTING";
export const CADASTRODEPRODUTOS_SEARCH = "CADASTRODEPRODUTOS_SEARCH";
export const CADASTRODEPRODUTOS_SEARCH_RESULT = "CADASTRODEPRODUTOS_SEARCH_RESULT";


export const CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE = "CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE";
export const CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE = "CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE";

export const CADASTRODEPRODUTOSB2B_SEARCH = "CADASTRODEPRODUTOSB2B_SEARCH";
export const CADASTRODEPRODUTOSB2B_SEARCH_RESULT = "CADASTRODEPRODUTOSB2B_SEARCH_RESULT";
export const CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE = "CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE";
export const CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE = "CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE";
export const B2B_RELATORIO_LIST = "B2B_RELATORIO_LIST";
export const B2B_RELATORIO_CHECKED_CHAMOU = "B2B_RELATORIO_CHECKED_CHAMOU";



export const PROCESSAMENTOS_DESCONTOFBITS_ISLOADING = "PROCESSAMENTOS_DESCONTOFBITS_ISLOADING";
export const PROCESSAMENTOS_DESCONTOFBITS_LIST = "PROCESSAMENTOS_DESCONTOFBITS_LIST";

export const PROCESSAMENTOS_PARCEIROS_LIST = "PROCESSAMENTOS_PARCEIROS_LIST";
export const PROCESSAMENTOS_PARCEIROS_ISSEARCHING = "PROCESSAMENTOS_PARCEIROS_ISSEARCHING";
export const PROCESSAMENTOS_PARCEIROS_ISSAVING = "PROCESSAMENTOS_PARCEIROS_ISSAVING";
export const PROCESSAMENTOS_PARCEIROS_CHANGE = "PROCESSAMENTOS_PARCEIROS_CHANGE";

export const PROCESSAMENTOS_CHANGE_LIBMARKETPLACE = "PROCESSAMENTOS_CHANGE_LIBMARKETPLACE";




export const AGRUPADOR_LIST = "AGRUPADOR_LIST";
export const AGRUPADOR_ISSEARCHING = "AGRUPADOR_ISSEARCHING";
export const AGRUPADOR_UPDATE_PARAMETROS = "AGRUPADOR_UPDATE_PARAMETROS";
export const AGRUPADOR_ISSAVING = "AGRUPADOR_ISSAVING";


export const BLACKFRIDAY_LIST_CLIENTES = "BLACKFRIDAY_LIST_CLIENTES";
export const BLACKFRIDAY_ISLOADING = "BLACKFRIDAY_ISLOADING";




//CORPORATIVO-------------------------------------------------------------------------------------------
export const CORPORATIVO_LIST_FECHAMENTO = "CORPORATIVO_LIST_FECHAMENTO";
export const CORPORATIVO_LOADER_FECHAMENTO = "CORPORATIVO_LOADER_FECHAMENTO";
export const CORPORATIVO_UPDATE_BUSCA = "CORPORATIVO_UPDATE_BUSCA";

export const CORPORATIVO_VALIDACAO_BUSCA_ADD = "CORPORATIVO_VALIDACAO_BUSCA_ADD";
export const CORPORATIVO_VALIDACAO_BUSCA_REMOVE = "CORPORATIVO_VALIDACAO_BUSCA_REMOVE";
export const CORPORATIVO_ISSEARCHING_BUSCA = "CORPORATIVO_ISSEARCHING_BUSCA";

export const CORPORATIVO_LIST = "CORPORATIVO_LIST";
export const CORPORATIVO_LIST_SEARCH = "CORPORATIVO_LIST_SEARCH";
export const CORPORATIVO_LIST_CIDADES = "CORPORATIVO_LIST_CIDADES";
//------------------------------------------------------------------------------------------------------


//Instaladores-------------------------------------------------------------------------------------------
export const INSTALADORES_UPDATE_BUSCA = "INSTALADORES_UPDATE_BUSCA";
export const INSTALADORES_ISSEARCHING_BUSCA = "INSTALADORES_ISSEARCHING_BUSCA";
export const INSTALADORES_LIST_SEARCH = "INSTALADORES_LIST_SEARCH";
//------------------------------------------------------------------------------------------------------


export const ROBODEPRECOS_LIST_CONCORRENTES = "ROBODEPRECOS_LIST_CONCORRENTES";
export const ROBODEPRECOS_LIST_GERAL = "ROBODEPRECOS_LIST_GERAL";
export const ROBODEPRECOS_LOADER_GERAL = "ROBODEPRECOS_LOADER_GERAL";
export const ROBODEPRECOS_LIST_PRODUTOS = "ROBODEPRECOS_LIST_PRODUTOS";


export const SERASA_UPDATE_BUSCA = "SERASA_UPDATE_BUSCA";
export const SERASA_ISSEARCHING_BUSCA = "SERASA_ISSEARCHING_BUSCA";
export const SERASA_LIST_SEARCH = "SERASA_LIST_SEARCH";


export const ORCAMENTOS_UPDATE_BUSCA = "ORCAMENTOS_UPDATE_BUSCA";
export const ORCAMENTOS_ISSEARCHING_BUSCA = "ORCAMENTOS_ISSEARCHING_BUSCA";
export const ORCAMENTOS_LIST_SEARCH = "ORCAMENTOS_LIST_SEARCH";



export const MERCADOLIVRE_LIST_TOKEN = "MERCADOLIVRE_LIST_TOKEN";
export const MERCADOLIVRE_CHANGE_DIMENSOES = "MERCADOLIVRE_CHANGE_DIMENSOES";
export const MERCADOLIVRE_DIMENSOES_SAVING = "MERCADOLIVRE_DIMENSOES_SAVING";

export const MERCADOLIVRE_ANUNCIO_UPDATE = "MERCADOLIVRE_ANUNCIO_UPDATE";
export const MERCADOLIVRE_ANUNCIO_SAVING = "MERCADOLIVRE_ANUNCIO_SAVING";
export const MERCADOLIVRE_ANUNCIO_CLEAR = "MERCADOLIVRE_ANUNCIO_CLEAR";

export const MERCADOLIVRE_LIST_PENDENTES = "MERCADOLIVRE_LIST_PENDENTES";
export const MERCADOLIVRE_LIST_ENVIADOS = "MERCADOLIVRE_LIST_ENVIADOS";
export const MERCADOLIVRE_CHECK_PENDENTES = "MERCADOLIVRE_CHECK_PENDENTES";



export const BANK_FILES_ML_LOADER = "BANK_FILES_ML_LOADER";
export const BANK_LIST_ML = "BANK_LIST_ML";

export const PENTEFINO_LIST = "PENTEFINO_LIST";
export const PENTEFINO_LIST_LOADER = "PENTEFINO_LIST_LOADER";


export const ORDEMCARGA_LIST_AUTO = "ORDEMCARGA_LIST_AUTO";
export const ORDEMCARGA_QTDE_AUTO = "ORDEMCARGA_QTDE_AUTO";
export const ORDEMCARGA_LOADER_AUTO = "ORDEMCARGA_LOADER_AUTO";
export const ORDEMCARGA_LOADER_ISUPDATING_AUTO = "ORDEMCARGA_LOADER_ISUPDATING_AUTO";
export const ORDEMCARGA_REMOVE_AUTO = "ORDEMCARGA_REMOVE_AUTO";





export const B2B_PARCEIROS_LIST = "B2B_PARCEIROS_LIST";
export const B2B_PARCEIROS_ISSEARCHING = "B2B_PARCEIROS_ISSEARCHING";
export const B2B_PARCEIROS_ISSAVING = "B2B_PARCEIROS_ISSAVING";
export const B2B_PARCEIROS_CHANGE = "B2B_PARCEIROS_CHANGE";



export const APPENTREGAS_UPDATE_NF = "APPENTREGAS_UPDATE_NF";
export const APPENTREGAS_LIST_NF = "APPENTREGAS_LIST_NF";
export const APPENTREGAS_LIST_ISSEARCHING = "APPENTREGAS_LIST_ISSEARCHING";


export const TRACKERS_PAINEL_LIST = "TRACKERS_PAINEL_LIST";
export const TRACKERS_PAINEL_LOADER = "TRACKERS_PAINEL_LOADER";
export const TRACKERS_PAINEL_LIST_SPLIT = "TRACKERS_PAINEL_LIST_SPLIT";
export const TRACKERS_PAINEL_SPLIT = "TRACKERS_PAINEL_SPLIT";
export const TRACKERS_PAINEL_SCREEN = "TRACKERS_PAINEL_SCREEN";
export const TRACKERS_PAINEL_LIST_CHANGE_LOGS = "TRACKERS_PAINEL_LIST_CHANGE_LOGS";
export const TRACKERS_LIST_REASONS = "TRACKERS_LIST_REASONS";
export const TRACKERS_DIFFERENT_CARS = "TRACKERS_DIFFERENT_CARS";


// ------------------ PERMISSOES MGR ---------------//
export const PERMISSOES_LIST_USUARIOS = "PERMISSOES_LIST_USUARIOS";
export const PERMISSOES_EDITING_USUARIOS = "PERMISSOES_EDITING_USUARIOS";
export const PERMISSOES_CHANGE_USUARIO_SAVING = "PERMISSOES_CHANGE_USUARIO_SAVING";
export const PERMISSOES_CHANGE_USUARIOS = "PERMISSOES_CHANGE_USUARIOS";


// --------------------- PIX MGR ---------------//
export const PIX_LIST_PIX = "PIX_LIST_PIX";
export const PIX_FILTRO_PIX = "PIX_FILTRO_PIX";


// --------------------- MONITORAMENTO MGR ---------------//
export const MONITORAMENTO_ERROS = "MONITORAMENTO_ERROS";


// --------------------- KPIVISITAS MGR ---------------//
export const KPIVISITAS_LIST = "KPIVISITAS_LIST";
export const KPIVISITAS_LOADING = "KPIVISITAS_LOADING";


// --------------------- ASSISTENCIA MGR ---------------//
export const ASSISTENCIA_LIST = "ASSISTENCIA_LIST";
export const ASSISTENCIA_LIST_LOADING = "ASSISTENCIA_LIST_LOADING";
export const ASSISTENCIA_LIST_FILTRO = "ASSISTENCIA_LIST_FILTRO";
export const ASSISTENCIA_GRID_LOADING = "ASSISTENCIA_GRID_LOADING";




export const ASSISTENCIA_MODAL = "ASSISTENCIA_MODAL";
export const ASSISTENCIA_MODAL_LOADING = "ASSISTENCIA_MODAL_LOADING";
