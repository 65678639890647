import React, { useState, useEffect } from 'react';
import { Modal, Form, Image, Button, Segment, TextArea, Icon, Dropdown } from 'semantic-ui-react';
import { assistenciaModalClearAction } from '../../actions/AssistenciaActions';
import StatusDropdown from './StatusDropdown ';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import If from '../../utils/If.js'

import styled from 'styled-components';


const ImageContainer = styled.div`
  display: inline-block;
  margin: 5px;
  text-align: center;
  cursor: pointer;

  img {
    width: 100px; // Ajuste o tamanho das imagens conforme necessário
    height: auto;
  }
`;

const ContainerWrapper = styled.div`
  margin-bottom: 10px; // Ajuste o espaço entre o título e os containers
`;

const Label = styled.label`
  display: block;
  font-size: 18px; // Ajuste o tamanho do título
  margin-bottom: 10px; // Espaço entre o título e o primeiro container
  text-align: left; // Alinha o título à esquerda (ou use 'center' se quiser centralizar)
  padding-left: 0;
`;

const TextContainer = styled.div`
  display: flex;
  overflow-x: auto;
  
  & > div {
    flex: 0 0 auto;
    width: 400px;
    margin-right: 10px;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
`;

const ClearButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f44336; /* Vermelho */
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: #d32f2f; /* Vermelho mais escuro */
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #b71c1c; /* Vermelho mais escuro ao clicar */
  }
`;

const IconWrapper = styled(Icon)`
  margin-right: 8px;
`;

const CharCounter = styled.div`
  position: absolute;
  bottom: 8px;
  right: 10px;
  font-size: 12px;
  color: #666;
`;

// Define o contêiner para os botões no rodapé
const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 70px;
  position: relative;
`;

// Alinha o dropdown de status no canto inferior esquerdo
const StatusDropdownWrapper = styled.div`
  position: absolute;
  bottom: 10px;

`;

// Alinha o botão de fechar no canto inferior direito
const CloseButtonWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const getStatusColor = (status) => {
    switch (status) {
        case 1:
            return '#20c997';
        case 2:
            return '#fd7e14';
        case 3:
            return '#28a745';
        case 4:
            return '#dc3545';
        case 5:
            return '#66abf9';
        default:
            return '#6c757d'; // Caso o status não seja 1, 2, 3 ou 4
    }
};

const StatusLabel = styled.span`
  background-color: ${props => getStatusColor(props.status)};
  color: white;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  font-weight: bold;
`;

function AssistenciaModal({ modalOpen, modal, handleModalClose }, props) {

    const [textareaValue, setTextareaValue] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(modal?.DataSource1?.[0].status);



    useEffect(() => {
        if (modal?.DataSource1?.length > 0) {
            setSelectedStatus(modal.DataSource1[0].status);
        }
    }, [modal]);
    const maxLength = 4000;

    const createImageURL = (buffer) => {
        const blob = new Blob([new Uint8Array(buffer)], { type: 'image/jpeg' }); // Ajuste o tipo de imagem conforme necessário
        return URL.createObjectURL(blob);
    };

    const handleTextClick = (text) => {
        setTextareaValue((prevValue) => {
            // Verifica se a adição do novo texto ultrapassará o limite
            if (prevValue.length + text.length <= maxLength) {
                let datareturn = prevValue ? prevValue + '\n' + text : text
                return datareturn
            } else {
                // Se ultrapassar, trunca o texto para que o limite não seja excedido
                const remainingChars = maxLength - prevValue.length;
                return prevValue + text.substring(0, remainingChars);
            }
        });
    };

    const handleTextareaChange = (e) => {
        setTextareaValue(e.target.value);
    };

    const handleClearText = () => {
        setTextareaValue('');
    }

    const handleStatusChange = (newStatus) => {
        setSelectedStatus(newStatus);
        // Aqui você pode adicionar lógica para salvar a alteração do status
    };

    const handleSave = () => {
        console.log('Resposta: ', textareaValue)
        console.log('Status: ', selectedStatus)

    }
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                size='large'
                scrolling
            > {modal?.DataSource1?.map((item) => {
                return (
                    <>
                        <Modal.Header>
                            <Modal.Header>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <div>
                                        <span>Protocolo: {item.protocolo}</span>
                                        <br />
                                        <span style={{ fontSize: '16px', color: '#666' }}>
                                            Nº Nota: {item.nunota}
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '16px' }}><StatusLabel status={item.status}>{item.status == 0 ? 'Aguardando Análise' : item.status == 1 ? 'Em análise' : item.status == 2 ? 'Aguardando Cliente' : item.status == 3 ? 'Procedente' : 4 ? 'Improcedente' : ''}</StatusLabel></span>
                                    </div>
                                    <div style={{ textAlign: 'right' }}>
                                        <span>
                                            Data de Criação: {new Date(item.dtcriacao).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '16px', color: '#666' }}>
                                            Prazo: {new Date(item.prazo).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            })}
                                        </span>
                                    </div>
                                </div>
                            </Modal.Header>

                        </Modal.Header>

                        <Modal.Content>

                            <Form>
                                <span style={{ fontSize: '23px' }}>Produto: <b>{item.produto}</b> ({item.fabricante}) </span>
                                <Segment>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Nome/Codparc</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Nome'
                                                name='nome'
                                                label={item.nomeparc + ' ' + '(' + item.codparc + ')'}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Telefone</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Telefone'
                                                name='telefone'
                                                label={item.telefone}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Email</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Email'
                                                name='email'
                                                label={item.email}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>CPF/CNPJ</label>
                                            <Form.Field
                                                fluid
                                                placeholder='CPF'
                                                name='cpf'
                                                label={item.cpf}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Cidade</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Cidade'
                                                name='cidade'
                                                label={item.cidade}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Bairro</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Bairro'
                                                name='bairro'
                                                label={item.bairro}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Endereço</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Endereço'
                                                name='endereco'
                                                label={item.endereco}
                                                readOnly
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Reclamação do cliente:</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Endereço'
                                                name='endereco'
                                                label={item.observacao}
                                                readOnly
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Anexos:</label>
                                            {modal.DataSource2.map((foto, index) => (
                                                foto && (
                                                    <ImageContainer key={index}>
                                                        <Image.Group size='medium' align='center'>
                                                            <a href={createImageURL(foto.arquivo.data)} target="_blank" rel="noopener noreferrer">
                                                                <Image
                                                                    src={createImageURL(foto.arquivo.data)}

                                                                />
                                                            </a>
                                                        </Image.Group>
                                                        <p>{foto.nomearq}</p>
                                                    </ImageContainer>
                                                )
                                            ))}
                                        </Form.Field>
                                    </Form.Group>
                                </Segment>
                                <ContainerWrapper>
                                    <Label>Templates:</Label>
                                    <TextContainer>
                                        <div>
                                            <Label><b>Negativa de defeito:</b></Label>
                                            <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nAgradecemos por nos contatar. Gostaríamos de esclarecer que a garantia do produto cobre defeitos de fabricação. Com base nas informações fornecidas, não identificamos indícios de um defeito de fabricação. No entanto, se você observar qualquer desacordo com o material conforme relatado, recomendamos que solicite a troca ou devolução do produto para que possamos resolver a questão adequadamente.\n\n Atenciosamente,`.replace(/<br\/?>/g, '\n'))}>
                                                Prezado(a) {item.nomeparc},<br /><br />
                                                Agradecemos por nos contatar. Gostaríamos de esclarecer que a garantia do produto cobre defeitos de fabricação. Com base nas informações fornecidas, não identificamos indícios de um defeito de fabricação. No entanto, se você observar qualquer desacordo com o material conforme relatado, recomendamos que solicite a troca ou devolução do produto para que possamos resolver a questão adequadamente.<br /><br />
                                                Atenciosamente,<br />
                                            </div>
                                        </div>

                                        <div>
                                            <Label><b>Negativa de coleta:</b></Label>
                                            <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nApós a realização da instalação, confirmamos que o material está em conformidade com as especificações e apto para uso. Portanto, não é possível proceder com a coleta e troca do produto neste momento.\n\n Atenciosamente, `.replace(/<br\/?>/g, '\n'))}>
                                                Prezado(a) {item.nomeparc},<br /><br />
                                                Após a realização da instalação, confirmamos que o material está em conformidade com as especificações e apto para uso. Portanto, não é possível proceder com a coleta e troca do produto neste momento.<br /><br />
                                                Atenciosamente,<br />
                                            </div>
                                        </div>

                                        <div>
                                            <Label><b>Solicitação de preenchimento de informações:</b></Label>
                                            <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nPara darmos continuidade ao atendimento, por favor, preencha a planilha em anexo e nos envie de volta com as informações solicitadas.\n\nAtenciosamente, `.replace(/<br\/?>/g, '\n'))}>
                                                Prezado(a) {item.nomeparc},<br /><br />
                                                Para darmos continuidade ao atendimento, por favor, preencha a planilha em anexo e nos envie de volta com as informações solicitadas.<br /><br />
                                                Atenciosamente,
                                            </div>
                                        </div>

                                        <div>
                                            <Label><b>Confirmação de vistoria não recebida:</b></Label>
                                            <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nInformamos que, como não recebemos a confirmação para a vistoria conforme solicitado no e-mail anterior, estamos encerrando o atendimento referente a este chamado.\n\nSe precisar de assistência adicional ou desejar reabrir o chamado no futuro, por favor, entre em contato conosco.\n\n Atenciosamente,  `.replace(/<br\/?>/g, '\n'))}>
                                                Prezado(a) {item.nomeparc},<br /><br />
                                                Informamos que, como não recebemos a confirmação para a vistoria conforme solicitado no e-mail anterior, estamos encerrando o atendimento referente a este chamado.<br /><br />
                                                Se precisar de assistência adicional ou desejar reabrir o chamado no futuro, por favor, entre em contato conosco.<br /><br />
                                                Atenciosamente,
                                            </div>
                                        </div>
                                    </TextContainer>
                                </ContainerWrapper>
                                <Form.Group widths='equal'>
                                    <Form.Field control={TextArea} label='Resposta:'
                                        placeholder='Digite ou selecione um Template para responder o cliente...'
                                        onChange={handleTextareaChange}
                                        value={textareaValue}
                                        maxLength={4000}
                                        rows='9'
                                    />
                                    <CharCounter>
                                        {textareaValue.length}/{maxLength}
                                    </CharCounter>
                                </Form.Group>
                                <ClearButton onClick={handleClearText}>
                                    <IconWrapper name='trash' /> Limpar
                                </ClearButton>
                            </Form>





                        </Modal.Content >

                    </>
                );
            })}
                <Modal.Content>
                    <FooterContainer>
                        <StatusDropdownWrapper>
                            <Form.Field>
                                <label>Status:</label>
                                <StatusDropdown value={selectedStatus} onChange={handleStatusChange} />
                            </Form.Field>
                        </StatusDropdownWrapper>
                        <CloseButtonWrapper>
                            <Button color='red' onClick={() => {
                                handleClearText();
                                handleModalClose();
                            }}
                            >
                                <Icon name='close' /> Fechar
                            </Button>
                            <If test={modal?.DataSource1?.[0].status !== 4}>
                                <If test={modal?.DataSource1?.[0].status !== 3}>
                                    <Button color='green' onClick={handleSave}>
                                        <Icon name='checkmark' /> Salvar
                                    </Button>
                                </If>
                            </If>
                        </CloseButtonWrapper>
                    </FooterContainer>
                </Modal.Content>
            </Modal >
        </>
    );
}

const mapStateToProps = (state) => ({
})


export default withRouter(connect(mapStateToProps, {
    assistenciaModalClearAction,
}
)(AssistenciaModal));