import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
    Table, Tab, Button, Icon, ModalHeader,
    ModalDescription,
    ModalContent,
    ModalActions,
    Modal,
    TextArea,
    Form,
    Dropdown,
    Input
} from 'semantic-ui-react';
import styled from 'styled-components';
import {
    pixListPixAction,
    pixFiltroPixAction
} from '../../actions/PixActions';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
  }lengthU
`;

const StyledTableHeader = styled(Table.Header)`
  &&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
  }
`;

const StyledTableBody = styled(Table.Body)`
  &&& td {
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }
`;
const StyledCell = styled(Table.Cell)`
&&&{
    div{
        font-size:15px !important;
    },
    span{
        font-size:15px !important;
      }
    }
`;

function ListaPix(props) {
    //actions
    const { pixListPixAction, pixFiltroPixAction } = props;

    //props reducer
    const { listpix, menu, history } = props;


    const [errors, setErrors] = useState(false);
    const [isLoadingList, setLoadingList] = useState(true);
    const [open, setOpen] = useState(false);
    const [pixPopup, setPixPopup] = useState([])
    const [pixIdPopup, setPixIdPopup] = useState(0)
    const [dropdownValue, setDropdownValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // ------ Effect para chamar as actions
    useEffect(() => {
        const today = new Date();
        setStartDate(today);

        let jsonData = {
            dropdownValues: null,
            inputValue: null,
            startDate: null,
            endDate: null
        };
        console.log(jsonData)
        pixListPixAction(jsonData).then((d) => {
            setLoadingList(false)
        });
        if (menu.filter(m => m.menuid === 27 && m.status === 1).length === 0) { history.push(`../Home`) }
    }, [menu]);
    const onOpenPopup = (usuario) => {
        setPixPopup([usuario]);
        setOpen(true);
        setPixIdPopup(usuario)
    };
    const stateOptions = [
        { text: 'Todos', value: 0 },
        { text: 'Nunota', value: 1 },
        { text: 'PixId', value: 2 },
        { text: 'Codparc', value: 3 }
    ];

    const handleSave = () => {

        // console.log('Buscar com Data Inicial:', startDate, 'e Data Final:', endDate);
        const jsonData = {
            dropdownValues: dropdownValue,
            inputValue: inputValue,
            startDate: startDate,
            endDate: endDate
        }

        const newErrors = [];
        if (dropdownValue != 0 && inputValue == '') {
            newErrors.push('O Busca é obrigatorio');
            setErrors(true)
        }
        if (newErrors.length === 0) {
            setLoadingList(true)
            setErrors(false)
            if (dropdownValue == 0 && inputValue == '' || dropdownValue == 0 && endDate == null) {
                pixListPixAction(jsonData).then((d) => {
                    countSim(d)
                    setLoadingList(false)
                })
            } else {
                pixFiltroPixAction(jsonData).then((d) => {
                    countSim(d)
                    setLoadingList(false)
                })
            }
        }
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Verifica se o valor contém apenas dígitos
            setInputValue(value);
        }
    };
    const countSim = (listpix) => {
        return listpix.filter(item => item.status_pagto === 1).length;
    };


    return (
        <Tab.Pane>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <ModalHeader>PixID({pixIdPopup.pixid}) NuNota({pixIdPopup.nunota})</ModalHeader>
                <ModalContent image scrolling>
                    <ModalDescription>
                        {pixPopup?.map(pixItem => (
                            <>
                                <div key={pixItem.pixid} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{ flex: '1 1 50%', padding: '10px' }}>
                                        <section>
                                            <h3>Informações do Devedor</h3>
                                            <span><strong>Devedor Nome:</strong> {pixItem.devedor_nome}</span><br></br>
                                            <span><strong>Devedor Documento:</strong> {pixItem.devedor_doc}</span><br></br>
                                            <span><strong>Codigo Parceiro:</strong> {pixItem.codparc}</span><br></br>
                                        </section>
                                        <hr></hr>
                                        <section>
                                            <h3>Mensagens do Vendedor</h3>
                                            <span><strong>Cod Vendedor:</strong> {pixItem.codvend}</span><br></br>
                                            <span><strong>Nome Vendedor:</strong> {pixItem.nome_vend}</span><br></br>
                                            <span><strong>Email Vendedor:</strong> {pixItem.email_vend}</span><br></br>
                                            <span><strong>ID Mensagem:</strong> {pixItem.mensagemid}</span><br></br>
                                            <span><strong>ID WT:</strong> {pixItem.wtid}</span><br></br>
                                            <span><strong>ID Mensagem Vendedor:</strong> {pixItem.mensagemid_vendedor}</span><br></br>
                                            <span><strong>ID Mensagem Vendedor Pagamento:</strong> {pixItem.mensagemid_vendedor_pagto}</span><br></br>
                                            <span><strong>Data Inserção:</strong>{new Date(pixItem.dt_insert).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            })} </span><br></br>
                                            <span><strong>Data Envio:</strong> {new Date(pixItem.mensagem_vendedor_dtenvio).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            })}</span><br></br>
                                            <span><strong>Status Mensagem:</strong> {pixItem.mensagem_vendedor_status}</span><br></br>
                                        </section>
                                        <hr></hr>
                                        <section>
                                            <h3>Mensagens do Cliente</h3>
                                            <span><strong>Email To:</strong> {pixItem.mensagem_cliente_emailto}</span><br></br>
                                            <span><strong>Data Envio:</strong> {new Date(pixItem.mensagem_cliente_dtenvio).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            })}</span><br></br>
                                            <span><strong>Status Mensagem:</strong> {pixItem.mensagem_cliente_status}</span><br></br>
                                        </section>
                                    </div>


                                    <div style={{ flex: '1 1 50%', padding: '10px' }}>
                                        <section>
                                            <h3>Detalhes da Transação</h3>
                                            <span><strong>TxID:</strong> {pixItem.txid}</span><br></br>
                                            <span><strong>Nunota:</strong> {pixItem.nunota}</span><br></br>
                                            <span><strong>E2EID:</strong> {pixItem.e2eid}</span><br></br>
                                            <span><strong>Valor:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pixItem.valor)}</span><br></br>
                                            <span><strong>Valor Original:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pixItem.valor_original)}</span><br></br>
                                            <span style={{ maxWidth: '500px', wordBreak: 'break-word', whiteSpace: 'normal', display: 'block' }}><strong>Copia e Cola:</strong> {pixItem.pix_cop_col}</span><br></br>

                                        </section>
                                        <hr></hr>
                                        <section>
                                            <h3>Status</h3>
                                            <span><strong>Status SQS:</strong> {pixItem.status_sqs == 0 ? 'Pendente' : pixItem.status_sqs == 1 ? 'Aguardando Itau' : pixItem.status_sqs == 2 ? 'Pix Criado' : pixItem.status_sqs == 3 ? 'Erro' : ''}</span><br></br>
                                            <span><strong>Data Status SQS:</strong> {new Date(pixItem.dt_status_sqs).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            })}</span><br></br>
                                            <span><strong>Status Pagamento:</strong> {pixItem.status_pagto == 0 ? 'Não' : 'Sim'}</span><br></br>
                                            <span><strong>Data Status Pagamento:</strong> {new Date(pixItem.dt_status_pagto).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            })}</span><br></br>
                                        </section>
                                        <hr></hr>
                                        <section>
                                            <h3>Mensagens de Pagamento</h3>
                                            <span><strong>Enviado Para:</strong>{pixItem.mensagem_pagto_vendedor_emailto}</span><br></br>
                                            <pspan><strong>Data Envio:</strong> {new Date(pixItem.mensagem_pagto_vendedor_dtenvio).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            })}</pspan><br></br>
                                            <span><strong>Status Mensagem:</strong> {pixItem.mensagem_pagto_vendedor_status}</span><br></br>
                                        </section>
                                        <hr></hr>
                                        <section>
                                            <h3>QRCode</h3>
                                            {/* <p><strong>QRCode:</strong> {pixItem.qrcode}</p> */}
                                            <img src={pixItem.qrcode_base64} alt="Imagem qrcode" />
                                            {/* <p><strong>QRCode Base64:</strong> {pixItem.qrcode_base64}</p> */}
                                        </section>
                                        <section>
                                            <h3>API</h3>
                                            <p><strong>API Request Pix:</strong></p>
                                            <Form>
                                                <TextArea>{JSON.stringify(pixItem.apirequest_pix, null, 2)}</TextArea>
                                                <p><strong>API Response Pix:</strong></p>
                                                <TextArea>{JSON.stringify(pixItem.apiresponse_pix, null, 2)}</TextArea>
                                                <p><strong>API Response Pagamento:</strong></p>
                                                <TextArea>{JSON.stringify(pixItem.apiresponse_pagto, null, 2)}</TextArea>
                                                <p><strong>Status Code Response:</strong> {pixItem.status_code_response}</p>
                                                <p><strong>Log Message:</strong></p>
                                                <TextArea>{JSON.stringify(pixItem.logmsg, null, 2)}</TextArea>
                                            </Form>
                                        </section>
                                    </div>
                                </div>
                                <section>
                                    <strong>Mensagem Pagamento Vendedor:</strong>
                                    <div style={{
                                        width: '800px',
                                        height: '300px', /* You can set a fixed height as per your requirement */
                                        overflowY: 'scroll',
                                        border: '1px solid #ccc',
                                        padding: '10px'
                                    }} ><p>{pixItem.mensagem_pagto_vendedor ? parse(pixItem.mensagem_pagto_vendedor) : ''}</p><hr></hr></div><br></br>
                                    <strong>Mensagem Cliente:</strong>
                                    <div style={{
                                        width: '800px',
                                        height: '300px', /* You can set a fixed height as per your requirement */
                                        overflowY: 'scroll',
                                        border: '1px solid #ccc',
                                        padding: '10px'
                                    }} ><p> {pixItem.mensagem_cliente ? parse(pixItem.mensagem_cliente) : ''}</p><hr></hr></div><br></br>
                                    <strong>Mensagem Vendedor:</strong>
                                    <div style={{
                                        width: '800px',
                                        height: '300px', /* You can set a fixed height as per your requirement */
                                        overflowY: 'scroll',
                                        border: '1px solid #ccc',
                                        padding: '10px'
                                    }} ><p>{pixItem.mensagem_vendedor ? parse(pixItem.mensagem_vendedor) : ''}</p><hr></hr></div><br></br>
                                </section>
                            </>
                        ))}
                    </ModalDescription>
                </ModalContent>
                <ModalActions>
                    <Button onClick={() => setOpen(false)} color='red'>
                        Fechar  <Icon name='cancel' />
                    </Button>
                </ModalActions>
            </Modal >


            <div key='1'>
            </div>
            <Tab.Pane style={{ margin: '0px' }} >
                <h3>Buscar Pix</h3>
                <StyledTable celled id="listPendentes" compact="very" size="">
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="1">NU/Pixid/Codparc</Table.HeaderCell>
                            <Table.HeaderCell width="3">Busca</Table.HeaderCell>
                            <Table.HeaderCell width="4">Data Inicial</Table.HeaderCell>
                            <Table.HeaderCell width="4">Data Final</Table.HeaderCell>
                            <Table.HeaderCell width="1"></Table.HeaderCell>
                        </Table.Row>
                    </StyledTableHeader>
                    <Table.Row>
                        <Table.Cell>
                            <Dropdown
                                selection
                                name='Filtrar'
                                options={stateOptions}
                                fluid
                                placeholder='Filtrar'
                                onChange={(e, { value }) => setDropdownValue(value)}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Input
                                error={errors}
                                onChange={handleInputChange}
                                value={inputValue} // Adiciona o valor do input controlado
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Form>
                                <div className="field">
                                    <div>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            isClearable={true}
                                            todayButton={"Hoje"}
                                            name="dtfinal"
                                            id="dtfinal"
                                            dateFormat="dd/MM/yyyy"
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </Table.Cell>
                        <Table.Cell>
                            <Form>
                                <div className="field">
                                    <div>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            isClearable={true}
                                            todayButton={"Hoje"}
                                            name="dtinicial"
                                            id="dtinicial"
                                            dateFormat="dd/MM/yyyy"
                                            required={true}
                                        />

                                    </div>
                                </div>
                            </Form>

                        </Table.Cell>
                        <Table.Cell>
                            <Button basic color="blue" onClick={handleSave}>
                                <Icon name="check" />
                                Buscar
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                </StyledTable>
            </Tab.Pane>
            <Tab.Pane loading={isLoadingList}>
                <StyledTable celled id="listPendentes" compact='very' size='small'>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="1">PixID</Table.HeaderCell>
                            <Table.HeaderCell width="4">Codparc/Nome</Table.HeaderCell>
                            <Table.HeaderCell width="1">Nunota</Table.HeaderCell>
                            <Table.HeaderCell width="1">Valor R$</Table.HeaderCell>
                            <Table.HeaderCell width="2">Data de Criação</Table.HeaderCell>
                            <Table.HeaderCell width="1">Status</Table.HeaderCell>
                            <Table.HeaderCell width="1">Pago({countSim(listpix)})</Table.HeaderCell>
                            <Table.HeaderCell width="2">Data Pagto</Table.HeaderCell>
                            <Table.HeaderCell>Detalhes</Table.HeaderCell>
                        </Table.Row>
                    </StyledTableHeader>
                    <StyledTableBody>
                        {listpix.map(item =>
                            <Table.Row key={"usuariosmgr" + item.pixid}>
                                <Table.Cell style={{ textAlign: 'center' }}>{item.pixid}</Table.Cell>
                                <Table.Cell >({item.codparc}) {item.devedor_nome}</Table.Cell>
                                <Table.Cell >{item.nunota}</Table.Cell>
                                <Table.Cell style={{ textAlign: 'center' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor)}</Table.Cell>
                                <Table.Cell >
                                    {new Date(item.dt_insert).toLocaleDateString('pt-BR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                    })}
                                </Table.Cell>
                                <Table.Cell style={{
                                    textAlign: 'center', color: item.status_sqs === 0
                                        ? 'black'
                                        : item.status_sqs === 1
                                            ? 'orange'
                                            : item.status_sqs === 2
                                                ? 'green'
                                                : item.status_sqs === 3
                                                    ? 'red'
                                                    : 'black',
                                }}>{item.status_sqs == 0 ? 'Pendente' : item.status_sqs == 1 ? 'Aguardando Itau' : item.status_sqs == 2 ? 'Pix Criado' : item.status_sqs == 3 ? 'Erro' : ''}</Table.Cell>
                                <Table.Cell style={{ textAlign: 'center', textAlign: 'center', color: item.status_pagto == 0 ? 'red' : 'green' }}>{item.status_pagto == 0 ? 'Não' : 'Sim'}</Table.Cell>
                                <Table.Cell style={{ textAlign: 'center' }}>{item.dt_status_pagto != null ? new Date(item.dt_status_pagto).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                }) : ''}</Table.Cell>
                                <Table.Cell>
                                    <Button
                                        // loading={item.isediting}
                                        onClick={() => onOpenPopup(item)}
                                        basic
                                        color='green'
                                        size='mini'
                                    >
                                        <Icon name='pencil' />
                                        Detalhes
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </StyledTableBody>
                </StyledTable>
            </Tab.Pane>

        </Tab.Pane >
    )
}

const mapStateToProps = (state) => ({
    menu: state.LoginReducer.menu
    , userid: state.LoginReducer.userid
    , listpix: state.PixReducer.listpix
})


export default withRouter(connect(mapStateToProps, {
    pixListPixAction,
    pixFiltroPixAction
}
)(ListaPix));