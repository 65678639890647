import {
    PIX_LIST_PIX,
    PIX_FILTRO_PIX
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    listpix: [],
    // filtropix: []
}

export default (state = INITIAL_STATE, action) => {


    switch (action.type) {

        case PIX_LIST_PIX:
            return { ...state, listpix: action.payload }

        case PIX_FILTRO_PIX:
            return { ...state, listpix: action.payload }

        default:
            return state;
    }
}