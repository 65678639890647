import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
var moment = require('moment');
import { kpivisitasListAction } from '../../actions/KpiVisitasActions';
import parse from 'html-react-parser';

function Visitas(props) {
    const [selectedAnoMes, setSelectedAnoMes] = useState(null);

    const { kpivisitasListAction } = props;

    const { kpivisitas } = props;


    useEffect(() => {
        kpivisitasListAction();
    }, []);

    const StyledDropdown = styled(Dropdown)`
  &&& {
    div{
        font-size:10px !important;
        width: 100% !important;
    }
    span{
        font-size:10px !important;
        width:80px !important;
    }
}`

    const arrayAnos = [...new Set(kpivisitas.map(item => moment(item.dt_ref).format('YYYY/MM')))];
    arrayAnos.sort((a, b) => new Date(b.replace('/', '-')) - new Date(a.replace('/', '-')));

    const formattedAnoMes = arrayAnos.map((dt_ref) => ({
        key: dt_ref,
        text: dt_ref,
        value: dt_ref,
    }));

    // Ordenando em ordem decrescente
    arrayAnos.sort((a, b) => b.localeCompare(a));

    const filteredData = selectedAnoMes ? kpivisitas.filter(item => moment(item.dt_ref).format('YYYY/MM') === selectedAnoMes) : kpivisitas.filter(item => moment(item.dt_ref).format('YYYY/MM') === arrayAnos[0]);
    const filteredDataAnt = selectedAnoMes ? kpivisitas.filter(item => moment(item.dt_ref).subtract('months', -1).format('YYYY/MM') === selectedAnoMes) : kpivisitas.filter(item => moment(item.dt_ref).format('YYYY/MM') === arrayAnos[1]);

    const handleAnoSelect = (e, { value }) => {
        setSelectedAnoMes(value);
    }

    function iconComponent(dado_atual, chave, index) {

        const valorAnterior = filteredDataAnt[index] ? filteredDataAnt[index][chave] : undefined;

        if (valorAnterior !== undefined && dado_atual !== null) {
            if (dado_atual > valorAnterior) {
                return <Icon name='arrow alternate circle up outline' color='green' />
            } else if (dado_atual < valorAnterior) {
                return <Icon name='arrow alternate circle down outline' color='red' />
            } else if (dado_atual === valorAnterior) {
                return <Icon name='arrow alternate circle right outline' color='grey' />
            }
        } else {
            return null;
        }
    }

    const truncateName = (name) => {
        const words = name.split(' ');

        if (words.length === 1) {
            return name; // Retorna o nome como está
        }

        if (words.length >= 2) {
            return `${words[0]} ${words[1][0]}.`; // Abrevia o segundo nome e ignora o restante
        }
    };

    return (
        <div style={{ marginTop: '10px', marginBottom: '40px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form>
                    <Form.Group widths='1' color='gray'>
                        <div className="field">
                            <div>
                                <StyledDropdown
                                    options={formattedAnoMes}
                                    selection
                                    placeholder='Ano/Mês'
                                    name='anomes'
                                    fluid
                                    value={selectedAnoMes ? selectedAnoMes : arrayAnos[0]}
                                    onChange={handleAnoSelect}
                                    scrolling
                                />
                            </div>

                        </div>
                    </ Form.Group>
                </ Form>
                <div style={{ display: 'flex', alignContent: 'center', marginBottom: '15px', marginLeft: '20px' }}>
                    <span style={{ marginRight: '20px' }}><b>Legenda de cores:</b></span>
                    <div style={{ width: '100px', height: '20px', borderTopLeftRadius: 4, borderBottomLeftRadius: 4, backgroundColor: '#005893', display: 'flex', justifyContent: 'center' }}>
                        <span style={{ color: '#fff', fontWeight: 'bold' }}>Excelente</span>
                    </div>
                    <div style={{ width: '100px', height: '20px', backgroundColor: '#7bc8f3', display: 'flex', justifyContent: 'center' }}>
                        <span style={{ color: '#fff', fontWeight: 'bold' }}>Bom</span>
                    </div>
                    <div style={{ width: '100px', height: '20px', backgroundColor: '#e3e4f7', display: 'flex', justifyContent: 'center' }}>
                        <span style={{ color: '#2d3349', fontWeight: 'bold' }}>Neutro</span>
                    </div>
                    <div style={{ width: '100px', height: '20px', backgroundColor: '#ff6284', display: 'flex', justifyContent: 'center' }}>
                        <span style={{ color: '#fff', fontWeight: 'bold' }}>Atenção</span>
                    </div>
                    <div style={{ width: '100px', height: '20px', backgroundColor: '#e61d3a', borderTopRightRadius: 4, borderBottomRightRadius: 4, display: 'flex', justifyContent: 'center' }}>
                        <span style={{ color: '#fff', fontWeight: 'bold' }}>Crítico</span>
                    </div>
                </div>
            </div>
            <table style={{ borderCollapse: 'collapse', overflow: 'hidden', borderRadius: 5 }}>
                <thead style={{ backgroundColor: '#cfd7f2', padding: 10 }}>
                    <tr key={Math.random()}>
                        <th style={{ fontSize: 12, padding: '10px 2px', }}>Nome</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '50px' }}>Pontos</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '170px' }}>Visitas Total</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '170px' }}>Visitas Novos</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '190px' }}>Visitas Inativos</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '170px' }}>Visitas Ativos</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '170px' }}>Faturamento Total</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '100px' }}>Meta %</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '100px' }}>C. Abertura</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '100px' }}>C. Reativ.</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '150px' }}>Ven. Display</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '150px' }}>Ven. Book</th>
                        <th style={{ fontSize: 12, padding: '10px 2px', width: '150px' }}>Total pontos</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item, index) => (
                        <tr style={{ borderBottom: '2px solid #d8d8d8' }} key={item.idkpi}>
                            <th key={item.idkpi} style={{
                                fontSize: 14, backgroundColor: `${item.pontos_total_cor === 'azul' ? '#005792' :
                                    item.pontos_total_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.pontos_total_cor === 'vermelho' ? '#E71D36' :
                                            item.pontos_total_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.pontos_total_cor === 'branco' ? '#e3e5f8' : null}`, width: '130px'
                            }}>
                                <span style={{ color: `${item.pontos_total_cor === 'branco' ? '#23252b' : '#fff'}` }}>{truncateName(item.consultor)}</span>

                            </th>

                            <th style={{ fontSize: 12, width: '150px', height: '50px', marginBottom: '10px' }}>
                                <div><Icon name='dollar sign' />
                                    Prêmio:
                                </div>
                                <div><Icon name='chart bar' />Índice:</div>
                                <div><Icon name='chart area' />Pontos:</div>
                            </th>
                            <th style={{
                                fontSize: 12, width: '100px', borderBottom: `15px solid ${item.visitas_total_cor === 'azul' ? '#005792' :
                                    item.visitas_total_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.visitas_total_cor === 'vermelho' ? '#E71D36' :
                                            item.visitas_total_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.visitas_total_cor === 'branco' ? '#e3e5f8' : null}`
                            }}>
                                <div>-</div>
                                <div>
                                    <Popup
                                        trigger=
                                        {iconComponent(item.visitas_total_qtde, 'visitas_total_qtde', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.visitas_total_qtde_hist}`)}

                                    </Popup>

                                    {item.visitas_total_qtde !== null || item.visitas_total_qtde === 0 ? item.visitas_total_qtde : '-'}

                                </div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.visitas_total_pontos, 'visitas_total_pontos', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.visitas_total_pontos_hist}`)}

                                    </Popup>

                                    {item.visitas_total_pontos !== null || item.visitas_total_pontos === 0 ? item.visitas_total_pontos : '-'}</div>

                            </th>
                            <th style={{
                                fontSize: 12, width: '100px', borderBottom: `15px solid ${item.visitas_novos_cor === 'azul' ? '#005792' :
                                    item.visitas_novos_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.visitas_novos_cor === 'vermelho' ? '#E71D36' :
                                            item.visitas_novos_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.visitas_novos_cor === 'branco' ? '#e3e5f8' : null}`
                            }}>

                                <div>-</div>
                                <div>
                                    <Popup
                                        trigger=
                                        {iconComponent(item.visitas_novos_qtde, 'visitas_novos_qtde', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.visitas_novos_qtde_hist}`)}


                                    </Popup>

                                    {item.visitas_novos_qtde ? item.visitas_novos_qtde : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.visitas_novos_pontos, 'visitas_novos_pontos', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.visitas_novos_pontos_hist}`)}

                                    </Popup>
                                    {item.visitas_novos_pontos !== null || item.visitas_novos_pontos !== 0 ? item.visitas_novos_pontos : '-'}</div>

                            </th>
                            <th style={{
                                fontSize: 12, width: '100px', borderBottom: `15px solid ${item.visitas_inativos_cor === 'azul' ? '#005792' :
                                    item.visitas_inativos_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.visitas_inativos_cor === 'vermelho' ? '#E71D36' :
                                            item.visitas_inativos_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.visitas_inativos_cor === 'branco' ? '#e3e5f8' : null}`
                            }}>

                                <div>-</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.visitas_inativos_qtde, 'visitas_inativos_qtde', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.visitas_inativos_qtde_hist}`)}

                                    </Popup>

                                    {item.visitas_inativos_qtde !== null || item.visitas_inativos_qtde !== 0 ? item.visitas_inativos_qtde : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.visitas_inativos_pontos, 'visitas_inativos_pontos', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.visitas_inativos_pontos_hist}`)}

                                    </Popup>

                                    {item.visitas_inativos_pontos !== null || item.visitas_inativos_pontos === 0 ? item.visitas_inativos_pontos : '-'}</div>

                            </th>
                            <th style={{
                                fontSize: 12, width: '100px', borderBottom: `15px solid ${item.visitas_ativos_cor === 'azul' ? '#005792' :
                                    item.visitas_ativos_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.visitas_ativos_cor === 'vermelho' ? '#E71D36' :
                                            item.visitas_ativos_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.visitas_ativos_cor === 'branco' ? '#e3e5f8' : null}`
                            }}>
                                <div>-</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.visitas_ativos_qtde, 'visitas_ativos_qtde', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.visitas_ativos_qtde_hist}`)}

                                    </Popup>

                                    {item.visitas_ativos_qtde !== null || item.visitas_ativos_qtde === 0 ? item.visitas_ativos_qtde : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.visitas_ativos_pontos, 'visitas_ativos_pontos', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.visitas_ativos_pontos_hist}`)}

                                    </Popup>

                                    {item.visitas_ativos_pontos !== null || item.visitas_ativos_pontos === 0 ? item.visitas_ativos_pontos : '-'}</div>

                            </th>
                            <th style={{
                                fontSize: 12, width: '100px', borderBottom: `15px solid ${item.faturamento_total_cor === 'azul' ? '#005792' :
                                    item.faturamento_total_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.faturamento_total_cor === 'vermelho' ? '#E71D36' :
                                            item.faturamento_total_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.faturamento_total_cor === 'branco' ? '#e3e5f8' : null}`
                            }}>

                                <div>-</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.faturamento_total_vlr, 'faturamento_total_vlr', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.faturamento_total_vlr_hist}`)}

                                    </Popup>

                                    {item.faturamento_total_vlr !== null || item.faturamento_total_vlr === 0 ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.faturamento_total_vlr) : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.faturamento_total_pontos, 'faturamento_total_pontos', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.faturamento_total_pontos_hist}`)}

                                    </Popup>

                                    {item.faturamento_total_pontos !== null || item.faturamento_total_pontos === 0 ? item.faturamento_total_pontos : '-'}</div>

                            </th>
                            <th style={{
                                fontSize: 12, width: '100px', borderBottom: `15px solid ${item.meta_cor === 'azul' ? '#005792' :
                                    item.meta_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.meta_cor === 'vermelho' ? '#E71D36' :
                                            item.meta_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.meta_cor === 'branco' ? '#e3e5f8' : null}`
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                    <div>

                                        <Popup
                                            trigger=
                                            {iconComponent(item.meta_vlr, 'meta_vlr', index)}

                                            flowing
                                            hoverable
                                            pinned
                                            on={["hover", "click"]}>

                                            {parse(`${item.meta_vlr_hist}`)}

                                        </Popup>

                                        {item.meta_vlr !== null || item.meta_vlr === 0 ? 'R$ ' + item.meta_vlr : '-'}</div>
                                    <div>

                                        <Popup
                                            trigger=
                                            {iconComponent(item.meta_pct, 'meta_pct', index)}

                                            flowing
                                            hoverable
                                            pinned
                                            on={["hover", "click"]}>

                                            {parse(`${item.meta_vlr_hist}`)}

                                        </Popup>

                                        {item.meta_pct !== null || item.meta_pct === 0 ? (item.meta_pct).toFixed(2) : '-'}</div>
                                    <div>

                                        <Popup
                                            trigger=
                                            {iconComponent(item.meta_pontos, 'meta_pontos', index)}

                                            flowing
                                            hoverable
                                            pinned
                                            on={["hover", "click"]}>

                                            {parse(`${item.meta_pontos_hist}`)}

                                        </Popup>

                                        {item.meta_pontos !== null || item.meta_pontos === 0 ? item.meta_pontos : '-'}</div>

                                </div>
                            </th>
                            <th style={{
                                fontSize: 12, width: '100px', borderBottom: `15px solid ${item.cliente_abertura_cor === 'azul' ? '#005792' :
                                    item.cliente_abertura_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.cliente_abertura_cor === 'vermelho' ? '#E71D36' :
                                            item.cliente_abertura_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.cliente_abertura_cor === 'branco' ? '#e3e5f8' : null}`
                            }}>
                                <div style={{ display: 'flex' }}>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.cliente_abertura_vlr, 'cliente_abertura_vlr', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.cliente_abertura_vlr_hist !== undefined ? item.cliente_abertura_vlr_hist : '-'}`)}

                                    </Popup>

                                    {item.cliente_abertura_vlr !== null || item.cliente_abertura_vlr === 0 ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.cliente_abertura_vlr) : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.cliente_abertura_qtde, 'cliente_abertura_qtde', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.cliente_abertura_qtde_hist}`)}

                                    </Popup>

                                    {item.cliente_abertura_qtde !== null || item.cliente_abertura_qtde === 0 ? item.cliente_abertura_qtde : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.cliente_abertura_pontos, 'cliente_abertura_pontos', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.cliente_abertura_pontos_hist}`)}

                                    </Popup>


                                    {item.cliente_abertura_pontos !== null || item.cliente_abertura_pontos === 0 ? item.cliente_abertura_pontos : '-'}</div>

                            </th>
                            <th style={{
                                fontSize: 12, width: '100px', borderBottom: `15px solid ${item.cliente_reativacao_cor === 'azul' ? '#005792' :
                                    item.cliente_reativacao_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.cliente_reativacao_cor === 'vermelho' ? '#E71D36' :
                                            item.cliente_reativacao_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.cliente_reativacao_cor === 'branco' ? '#e3e5f8' : null}`
                            }}>
                                <div style={{ display: 'flex' }}>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.cliente_reativacao_vlr, 'cliente_reativacao_vlr', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.cliente_reativacao_vlr_hist !== undefined ? item.cliente_reativacao_vlr_hist : '-'}`)}

                                    </Popup>

                                    {item.cliente_reativacao_vlr !== null || item.cliente_reativacao_vlr === 0 ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.cliente_reativacao_vlr) : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.cliente_reativacao_qtde, 'cliente_reativacao_qtde', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.cliente_reativacao_qtde_hist}`)}

                                    </Popup>

                                    {item.cliente_reativacao_qtde !== null || item.cliente_reativacao_qtde === 0 ? item.cliente_reativacao_qtde : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.cliente_reativacao_pontos, 'cliente_reativacao_pontos', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.cliente_reativacao_pontos_hist}`)}

                                    </Popup>

                                    {item.cliente_reativacao_pontos !== null || item.cliente_reativacao_pontos === 0 ? item.cliente_reativacao_pontos : '-'}</div>
                            </th>
                            <th style={{
                                fontSize: 12, width: '100px', borderBottom: `15px solid ${item.venda_display_cor === 'azul' ? '#005792' :
                                    item.venda_display_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.venda_display_cor === 'vermelho' ? '#E71D36' :
                                            item.venda_display_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.venda_display_cor === 'branco' ? '#e3e5f8' : null}`
                            }}>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.venda_display_vlr, 'venda_display_vlr', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.venda_display_vlr_hist !== undefined ? item.venda_display_vlr_hist : '-'}`)}

                                    </Popup>

                                    {item.venda_display_vlr !== null || item.venda_display_vlr === 0 ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.venda_display_vlr) : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.venda_display_qtde, 'venda_display_qtde', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.venda_display_qtde_hist}`)}

                                    </Popup>

                                    {item.venda_display_qtde !== null || item.venda_display_qtde === 0 ? item.venda_display_qtde : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.venda_display_pontos, 'venda_display_pontos', index)}
                                        position='top right'
                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.venda_display_pontos_hist}`)}

                                    </Popup>

                                    {item.venda_display_pontos !== null || item.venda_display_pontos === 0 ? item.venda_display_pontos : '-'}</div>
                            </th>
                            <th style={{
                                fontSize: 12, width: '100px', borderBottom: `15px solid ${item.venda_book_cor === 'azul' ? '#005792' :
                                    item.venda_book_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.venda_book_cor === 'vermelho' ? '#E71D36' :
                                            item.venda_book_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.venda_book_cor === 'branco' ? '#e3e5f8' : null}`
                            }}>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.venda_book_vlr, 'venda_book_vlr', index)}

                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.venda_book_vlr_hist !== undefined ? item.venda_book_vlr_hist : '-'}`)}

                                    </Popup>

                                    {item.venda_book_vlr !== null || item.venda_book_vlr === 0 ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.venda_book_vlr) : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.venda_book_qtde, 'venda_book_qtde', index)}
                                        position='top right'
                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.venda_book_qtde_hist !== undefined ? item.venda_book_qtde_hist : '-'}`)}

                                    </Popup>

                                    {item.venda_book_qtde !== null || item.venda_book_qtde === 0 ? item.venda_book_qtde : '-'}</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.venda_book_pontos, 'venda_book_pontos', index)}
                                        position='top right'
                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.venda_book_pontos_hist !== undefined ? item.venda_book_pontos_hist : '-'}`)}

                                    </Popup>

                                    {item.venda_book_pontos !== null || item.venda_book_pontos === 0 ? item.venda_book_pontos : '-'}</div>
                            </th>
                            <th style={{
                                fontSize: 12, width: '100px', borderBottom: `15px solid ${item.pontos_total_cor === 'azul' ? '#005792' :
                                    item.pontos_total_cor === 'azul_claro' ? '#7BC8F6' :
                                        item.pontos_total_cor === 'vermelho' ? '#E71D36' :
                                            item.pontos_total_cor === 'vermelho_claro' ? '#FF6384' :
                                                item.pontos_total_cor === 'branco' ? '#e3e5f8' : null}`
                            }}>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.premio_total, 'premio_total', index)}
                                        position='top right'
                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.premio_total_hist !== undefined ? item.premio_total_hist : '-'}`)}

                                    </Popup>

                                    {item.premio_total !== null || item.premio_total === 0 ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.premio_total) : '-'}</div>
                                <div>-</div>
                                <div>

                                    <Popup
                                        trigger=
                                        {iconComponent(item.pontos_total, 'pontos_total', index)}
                                        position='top right'
                                        flowing
                                        hoverable
                                        pinned
                                        on={["hover", "click"]}>

                                        {parse(`${item.pontos_total_hist !== undefined ? item.pontos_total_hist : '-'}`)}

                                    </Popup>

                                    {item.pontos_total !== null || item.pontos_total === 0 ? item.pontos_total : '-'}</div>
                            </th>
                        </tr>


                    ))}
                </tbody>
            </table >
        </div >
    );
}

const mapStateToProps = (state) => ({
    kpivisitas: state.KpiVisitasReducer.kpivisitas
})


export default withRouter(connect(mapStateToProps, {
    kpivisitasListAction
}
)(Visitas));
