import * as Api from '../utils/API';
import {
    PIX_LIST_PIX,
    PIX_FILTRO_PIX
} from '../actions/ActionsTypes';

export const pixListPixAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log(entidade)
        Api.getAllListPix(entidade).then(dados => {
            dispatch({ type: PIX_LIST_PIX, payload: dados })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });

export const pixFiltroPixAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // console.log(entidade)
        Api.postFiltroPix(entidade).then(dados => {
            dispatch({ type: PIX_FILTRO_PIX, payload: dados })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });
