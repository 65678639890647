import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/pt-br'

import {
  appEntregasHandleChangeAction
  , appEntregasHandleCancelarAction
  , appEntregasHandleSearchAction
} from '../../actions/AppEntregasActions';

import {
  Table, Button, Input, Form, Segment, Message, Tab, Icon, Menu, Breadcrumb, Grid, Checkbox
  , Header, Image
} from 'semantic-ui-react';

import ReactToPrint from "react-to-print";

import If from '../../utils/If';

import styled from 'styled-components';

import ReactExport from "react-data-export";


import _ from "lodash";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;








const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


const MyMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDkYZGyXi8QjtnjZOwfJj-qg5uilkPpSzs&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `250px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={17} defaultCenter={{ lat: props.lat, lng: props.lng }}>
    <Marker position={{ lat: props.lat, lng: props.lng }} />
  </GoogleMap>
));



class AppEntregas extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidMount() {

    //Verificando permissão de funcionalidade do MENU-------------------
    let { menu, history } = this.props;
    if (menu.filter(m => m.menuid === 24 && m.status === 1).length === 0) { history.push(`/Home`) }
    //------------------------------------------------------------------

  }







  render() {

    let { consulta, parametros, issearching } = this.props;

    let { dadosnf } = this.props;

    let panes = [];


    let validacampo = () => {

    }





    panes.push({
      menuItem: <Menu.Item key='TABpar'><Icon name='list alternate outline' />Comprovante de Entrega</Menu.Item>,
      render: () =>
        <Tab.Pane>
          <Grid columns={1} relaxed='very'>
            <Grid.Column verticalAlign='middle'>
              <Form>

                <Input
                  action={{
                    color: 'blue', content: 'Buscar', onClick: this.props.appEntregasHandleSearchAction
                  }}
                  icon='search'
                  iconPosition='left'
                  placeholder='Nota Fiscal #'
                  onChange={this.props.appEntregasHandleChangeAction}
                  loading={this.props.issearchingnf}
                  name='nf'
                  maxLength={10}
                  value={this.props.searchnf}
                />
              </Form>

            </Grid.Column>


            <Grid.Column>



              <div ref={el => (this.componentRef = el)}>
                {dadosnf.length > 0 && (

                  <table width="100%" border="0">

                    <tr>
                      <td colSpan='3'>
                        
                        <table cellPadding='0' cellSpacing='0' width='100%'>
                          <tr>
                            <td><Image src='img/logo_esplane.png' size='small' align="left" valign="middle"/></td>
                            <td><b>COMPROVANTE DE ENTREGA DA MERCADORIA</b></td>
                          </tr>
                        </table>
                        
                        
                        </td>
                      
                    </tr>
                    <tr>
                      <td><b>NF:</b> {dadosnf[0].nota[0].numnota}</td>
                      <td><b>OC:</b> {dadosnf[0].nota[0].ordemcarga}</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td><b>Cliente:</b> {dadosnf[0].nota[0].parceiro}</td>
                      <td colspan="2"><b>Data Entrega:</b> {moment(dadosnf[0].nota[0].ad_appcheckoutdh).format('DD/MM/YYYY HH:mm')}</td>
                    </tr>

                    <tr>
                      <td><b>Endereço:</b> {dadosnf[0].nota[0].enderecoentrega} </td>
                      <td><b>Vendedor:</b> {dadosnf[0].nota[0].vendedor}</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td> <b>Motorista:</b> {dadosnf[0].nota[0].motorista}</td>
                      <td  colSpan="2"><b>Data Pedido:</b> {moment(dadosnf[0].nota[0].datapedido).format('DD/MM/YYYY')}</td>
                    </tr>
                    <tr>
                      <td colSpan="3"><hr></hr></td>
                    </tr>
                    <tr>
                      <td colSpan="3" align="center"><b>Início da Entrega:</b> {moment(dadosnf[0].nota[0].ad_appdtinicentr).format('DD/MM/YYYY HH:mm:ss')}</td>
                    </tr>
                    <tr>
                      <td colSpan="3"><hr></hr></td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <table width="100%">
                          <tr>
                            <td><b>Check-in:</b> {moment(dadosnf[0].nota[0].ad_appcheckindh).format('DD/MM/YYYY HH:mm:ss')}</td>
                            <td><b>Check-out:</b> {moment(dadosnf[0].nota[0].ad_appcheckoutdh).format('DD/MM/YYYY HH:mm:ss')}</td>
                          </tr>
                          <tr>
                            <td valign='top'><MyMap key="mapcheckin" lat={Number.parseFloat(dadosnf[0].nota[0].ad_appcheckinlatitude)} lng={Number.parseFloat(dadosnf[0].nota[0].ad_appcheckinlongitude)} /></td>
                            <td valign='top'><MyMap key="mapcheckout" lat={Number.parseFloat(dadosnf[0].nota[0].ad_appcheckoutlatitude)} lng={Number.parseFloat(dadosnf[0].nota[0].ad_appcheckoutlongitude)} /></td>
                          </tr>

                        </table>

                      </td>
                    </tr>

                    <If test={dadosnf[0].fotos.length > 0}>
                      <tr>
                        <td colSpan="3"><hr></hr></td>
                      </tr>
                      <tr>
                        <td colSpan="3" align="center"><b>Fotos Registradas</b></td>
                      </tr>
                      <tr>
                        <td colSpan="3"><hr></hr></td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <Image.Group size='medium'>
                            {dadosnf[0].fotos.map(data => (
                              <Image src={`data:image/png;base64,${data.fotoclob}`} />
                            ))}
                          </Image.Group>
                        </td>
                      </tr>

                    </If>

                    

                    <If test={dadosnf[0].ocorrencias.length > 0}>
                      <tr>
                        <td colSpan="3"><hr></hr></td>
                      </tr>
                      <tr>
                        <td colSpan="3" align="center"><b>Ocorrências</b></td>
                      </tr>
                      <tr>
                        <td colSpan="3"><hr></hr></td>
                      </tr>
                      <tr>
                        <td colSpan="3">
                          <StyledTable celled id={'cpprodutos'} compact='very' size='small'>
                            <StyledTableHeader>
                              <Table.Row>

                                <Table.HeaderCell width="4">Ocorrência</Table.HeaderCell>
                                <Table.HeaderCell width="1">Data</Table.HeaderCell>
                                {//<Table.HeaderCell width="1">Tipo</Table.HeaderCell>
                                }
                              </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                              {dadosnf[0].ocorrencias.map(item => (
                                <Table.Row key={'ocorr' + item.seq}>
                                  <Table.Cell>{item.descrocor}</Table.Cell>
                                  <Table.Cell>{moment(item.dhocor).format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>
                                  {
                                    //<Table.Cell>{item.tipo}</Table.Cell>
                                  }

                                </Table.Row>
                              ))}
                            </StyledTableBody>
                          </StyledTable>
                        </td>
                      </tr>
                    </If>


                    <If test={dadosnf[0].produtos.length > 0}>
                      <tr>
                        <td colSpan="3"><hr></hr></td>
                      </tr>
                      <tr>
                        <td colSpan="3" align="center"><b>Produtos</b></td>
                      </tr>
                      <tr>
                        <td colSpan="3"><hr></hr></td>
                      </tr>
                      <tr>
                        <td colSpan="3">
                          <StyledTable celled id={'cpprodutos'} compact='very' size='small'>
                            <StyledTableHeader>
                              <Table.Row>
                                <Table.HeaderCell width="1">SKU</Table.HeaderCell>
                                <Table.HeaderCell width="4">Produto</Table.HeaderCell>
                                <Table.HeaderCell width="1">Quantidade</Table.HeaderCell>
                                <Table.HeaderCell width="1">Volume</Table.HeaderCell>
                                <Table.HeaderCell width="1">Unitário</Table.HeaderCell>
                                <Table.HeaderCell width="1">Total</Table.HeaderCell>
                              </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                              {dadosnf[0].produtos.map(item => (
                                <Table.Row key={'sku' + item.codprod}>
                                  <Table.Cell>{item.codprod}</Table.Cell>
                                  <Table.Cell>{item.descrprod}</Table.Cell>
                                  <Table.Cell>{item.qtdneg}</Table.Cell>
                                  <Table.Cell>{item.codvol}</Table.Cell>
                                  <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrunit)}</Table.Cell>
                                  <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrtot)}</Table.Cell>
                                </Table.Row>
                              ))}
                            </StyledTableBody>
                          </StyledTable>
                        </td>
                      </tr>
                    </If>

                    <If test={dadosnf[0].nota[0].ad_appassinaturaclob != null}>
                      <tr>
                        <td colSpan="3"><hr></hr></td>
                      </tr>
                      <tr>
                        <td colSpan="3" align="center"><b>Assinatura</b></td>
                      </tr>
                      <tr>
                        <td colSpan="3"><hr></hr></td>
                      </tr>
                      <tr>
                        <td colSpan="3">
                          <table width="100%" border="0">
                            <tr>
                              <td width="50%"><b>Data Assinatura:</b> {moment(dadosnf[0].nota[0].ad_appassinadodh).format('DD/MM/YYYY HH:mm:ss')}</td>
                              <td><b>Local Assinatura:</b></td>
                            </tr>
                            <tr>
                              <td valign='top'>

                                <Image src={`data:image/png;base64,${dadosnf[0].nota[0].ad_appassinaturaclob}`} />

                                <b>{dadosnf[0].nota[0].ad_apprecebedor}</b><br></br>
                                <b>{dadosnf[0].nota[0].ad_appripdocrecebedor}:</b> {dadosnf[0].nota[0].ad_appdocrecebedor}

                              </td>
                              <td valign='top'><MyMap key="mapcheckin" lat={Number.parseFloat(dadosnf[0].nota[0].ad_appcheckinlatitude)} lng={Number.parseFloat(dadosnf[0].nota[0].ad_appcheckinlongitude)} /></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </If>



                  </table>

                )}




              </div>


            </Grid.Column>
            {dadosnf.length > 0 && (
              <Grid.Column>
                <ReactToPrint
                  trigger={() => <Button color='green'>Imprimir</Button>}
                  content={() => this.componentRef}
                  pageStyle="@media print {pre, blockquote, table, div {page-break-inside: avoid; border: 1px solid #ffff;}}"
                //onAfterPrint={(e) =>this.props.handlePrint(this.props.datalist)}


                />

                <Button secondary
                  onClick={e => this.props.appEntregasHandleCancelarAction()}
                >Cancelar</Button>
              </Grid.Column>
            )}


          </Grid>
        </Tab.Pane>
    })

    return (

      <div>

        <Menu stackable size='mini'>
          <Menu.Item>
            <Breadcrumb size='mini'>
              <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
              <Breadcrumb.Divider icon='right chevron' />
              <Breadcrumb.Section active>APP Entregas </Breadcrumb.Section>
            </Breadcrumb>
          </Menu.Item>
        </Menu>


        <Grid columns={1} doubling>
          <Grid.Column>
            <Tab panes={panes} />
          </Grid.Column>
        </Grid>


      </div>

    )

  }

}


const mapStateToProps = state => ({
  menu: state.LoginReducer.menu

  , searchnf: state.AppEntregasReducer.searchnf
  , dadosnf: state.AppEntregasReducer.dadosnf
  , issearchingnf: state.AppEntregasReducer.issearchingnf


})

export default withRouter(connect(mapStateToProps, {
  appEntregasHandleChangeAction
  , appEntregasHandleCancelarAction
  , appEntregasHandleSearchAction
}
)(AppEntregas));
