import React, { useEffect } from 'react';
import {
    Grid, Menu, Breadcrumb, Tab, Label, Icon
} from 'semantic-ui-react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ListaPix from './ListaPix';
import { pixListPixAction } from '../../actions/PixActions';

function Pix(props) {
    useEffect(() => {
        pixListPixAction();
    }, [])

    const { pixListPixAction } = props;
    const { listpix } = props;
    let panes = [
        {
            menuItem: <Menu.Item key='TABpendente'><Icon name='money bill alternate outline' />Pix<Label color='green'>{listpix.length}</Label></Menu.Item>,
            render: () =>
                <ListaPix />,
        },
    ]


    return (
        <>
            <Menu stackable size='mini'>
                <Menu.Item>
                    <Breadcrumb size='mini'>
                        <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' />
                        <Breadcrumb.Section active>Pix</Breadcrumb.Section>
                    </Breadcrumb>
                </Menu.Item>
            </Menu>
            <div>
                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    listpix: state.PixReducer.listpix

})
export default withRouter(connect(mapStateToProps, {
    pixListPixAction,
}
)(Pix));