import React, { useState, useEffect } from 'react';


import {
    Grid, Menu, Breadcrumb, Tab, Icon
} from 'semantic-ui-react'
import ListAssistencia from './LisAssistencia';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { assistenciaListAction } from '../../actions/AssistenciaActions';


function Assistencia(props) {

    const { assistenciaListAction } = props;// actions
    const [dadosCarregados, setDadosCarregados] = useState(false);

    useEffect(() => {
        assistenciaListAction().then(() => {
            setDadosCarregados(true);
        });
    }, [assistenciaListAction]);

    let panes = [
        {
            menuItem: <Menu.Item key='TABpendente'><Icon name='bullhorn' />Análise</Menu.Item>,
            render: () => dadosCarregados ? <ListAssistencia /> : null
        }
    ];


    return (
        <>
            <Menu stackable size='mini'>
                <Menu.Item>
                    <Breadcrumb size='mini'>
                        <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' />
                        <Breadcrumb.Section active>Assistência</Breadcrumb.Section>
                    </Breadcrumb>
                </Menu.Item>
            </Menu>
            <div>
                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        </>

    );
}

const mapStateToProps = (state) => ({
    assistencias: state.AssistenciaReducer.assistencia
})


export default withRouter(connect(mapStateToProps, {
    assistenciaListAction
}
)(Assistencia));
