import * as Api from '../utils/API';
import {
    ASSISTENCIA_LIST, ASSISTENCIA_LIST_LOADING, ASSISTENCIA_MODAL, ASSISTENCIA_MODAL_LOADING, ASSISTENCIA_LIST_FILTRO, ASSISTENCIA_GRID_LOADING
} from '../actions/ActionsTypes';

export const assistenciaListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ASSISTENCIA_LIST_LOADING, payload: true })
        Api.getListAssistenciaMGR().then(dados => {
            dispatch({ type: ASSISTENCIA_LIST, payload: dados })
            dispatch({ type: ASSISTENCIA_LIST_LOADING, payload: false })
            dispatch({ type: ASSISTENCIA_GRID_LOADING, payload: false })
            dispatch({ type: ASSISTENCIA_LIST_FILTRO, payload: true })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });


export const assistenciaListFiltroAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // console.log(dados)
        dispatch({ type: ASSISTENCIA_LIST_LOADING, payload: true })
        const data = { status: dados }
        Api.getListFiltroAssistenciaMGR(data).then(dados => {
            // console.log(dados)
            dispatch({ type: ASSISTENCIA_LIST, payload: dados })
            dispatch({ type: ASSISTENCIA_LIST_LOADING, payload: false })
            dispatch({ type: ASSISTENCIA_LIST_FILTRO, payload: false })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });


export const assistenciaModalAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: ASSISTENCIA_MODAL_LOADING, payload: true })
        const data = { assistencia_id: dados }

        Api.getModalAssistenciaMGR(data).then(dados => {
            dispatch({ type: ASSISTENCIA_MODAL, payload: dados })
            dispatch({ type: ASSISTENCIA_MODAL_LOADING, payload: false })
            resolve('OK')
        }).catch(error => {
            reject(error);
        })
    });

export const assistenciaModalClearAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ASSISTENCIA_MODAL, payload: [] })
    });

export const assistenciaStatusAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log('chamou', dados)

        Api.postStatusAssistenciaMGR(dados).then(dados => {
            console.log(dados)
            resolve('OK')
        }).catch(error => {
            reject(error);
        })
    });



