import sortBy from 'sort-by';

import {
    DESPESASDEVIAGENS_LIST_SAIDAS
    , DESPESASDEVIAGENS_LIST_AJUDANTE
    , DESPESASDEVIAGENS_LIST_MOTORISTAS
    , DESPESASDEVIAGENS_LIST_AJUDANTES
    , DESPESASDEVIAGENS_LOADER_SAIDAS

    , DESPESASDEVIAGENS_CHANGE_SAIDAS_MOTORISTA
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEUM
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEDOIS
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_DTSAIDA
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_ALTEROU
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_REMOVE
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_SAVING


    , DESPESASDEVIAGENS_LIST_CHEGADAS
    , DESPESASDEVIAGENS_LOADER_CHEGADAS
    , DESPESASDEVIAGENS_CHANGE_CHEGADAS_DTCHEGADA

    , DESPESASDEVIAGENS_LIST_VIAGEMDESPESAS
    , DESPESASDEVIAGENS_CHANGE_CHEGADAS
    , DESPESASDEVIAGENS_INSERT_CHEGADAS

    , DESPESASDEVIAGENS_CHANGE_CHEGADAS_SAVING
    , DESPESASDEVIAGENS_CHANGE_CHEGADAS_REMOVE
    , DESPESASDEVIAGENS_LIST_FERIADOS

    , DESPESASDEVIAGENS_LIST_RECIBOS
    , DESPESASDEVIAGENS_LOADER_RECIBOS
    , DESPESASDEVIAGENS_CHANGEDT_RECIBOS

    , DESPESASDEVIAGENS_LIST_ASSINATURAS
    , DESPESASDEVIAGENS_LOADER_ASSINATURAS
    , DESPESASDEVIAGENS_CHANGEDT_ASSINATURAS
    , DESPESASDEVIAGENS_LIST_ASSINATURAS_RH

    , DESPESASDEVIAGENS_LIST_FINANCEIROAPROCESSAR
    , DESPESASDEVIAGENS_LOADER_FINANCEIROAPROCESSAR

    , DESPESASDEVIAGENS_LIST_FINANCEIROGERADO
    , DESPESASDEVIAGENS_LOADER_FINANCEIROGERADO
    , DESPESASDEVIAGENS_LIST_FINANCEIROGERADO_USERSFIN
    , DESPESASDEVIAGENS_CHANGE_FINANCEIROGERADO_SAVING
    , DESPESASDEVIAGENS_SAVING_AJUDANTE
    , DESPESASDEVIAGENS_EDITING_AJUDANTE
    , DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_NOME
    , DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_ATIVO
    , DESPESASDEVIAGENS_CHANGE_AJUDANTE_SAVING
    , DESPESASDEVIAGENS_REMOVE_FINANCEIROGERADO
    , DESPESASDEVIAGENS_REMOVE_FINANCEIROGERADO_OC

    , DESPESASDEVIAGENS_CHANGE_ISCANCELING
    , DESPESASDEVIAGENS_REMOVE_ORDEMCARGA
    , DESPESASDEVIAGENS_ADICIONAL_INSERT_CHEGADAS
    , DESPESASDEVIAGENS_LIST_ADICIONAL

    , DESPESASDEVIAGENS_UPDATE_BUSCA
    , DESPESASDEVIAGENS_VALIDACAO_BUSCA_ADD
    , DESPESASDEVIAGENS_VALIDACAO_BUSCA_REMOVE
    , DESPESASDEVIAGENS_ISSEARCHING_BUSCA
    , DESPESASDEVIAGENS_LIST_SEARCH

    , DESPESASDEVIAGENS_LIST_MESMODIA
    , DESPESASDEVIAGENS_ISREMOVING_MESMODIA
    , DESPESASDEVIAGENS_REMOVE_MESMODIA

} from '../actions/ActionsTypes';

import { RJBuscaEntity } from '../entities/RJBuscaEntity'

const INITIAL_STATE = {
    saidas: []
    , chegadas: []
    , ajudante: []
    , motoristas: []
    , ajudantes: []
    , viagemdespesas: []
    , adicionallist: []
    , loaderDespesasDeViagensSaida: true
    , loaderDespesasDeViagensChegada: true

    , despesas: []
    , despesasAdicional: []
    , feriados: []

    , recibos: []
    , loaderDespesasDeViagensRecibos: true

    , assinaturas: []
    , loaderDespesasDeViagensAssinaturas: true
    , usersrh: []

    , financeiroaprocessar: []
    , loaderDespesasDeViagensFinanceiroAProcessar: true

    , financeirogerado: []
    , loaderDespesasDeViagensFinanceiroGerado: true
    , usersfin: []

    , iscanceling: false
    , RJBuscaEntity
    , isSearching: false
    , validacao: []
    , search: []

    , mesmodia: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case DESPESASDEVIAGENS_LIST_AJUDANTE:
            return { ...state, ajudante: action.payload }

        case DESPESASDEVIAGENS_LIST_SAIDAS:
            return { ...state, saidas: action.payload }

        case DESPESASDEVIAGENS_LOADER_SAIDAS:
            return { ...state, loaderDespesasDeViagensSaida: action.payload }

        case DESPESASDEVIAGENS_LIST_MOTORISTAS:
            return { ...state, motoristas: action.payload }

        case DESPESASDEVIAGENS_LIST_AJUDANTES:
            return { ...state, ajudantes: action.payload }

        case DESPESASDEVIAGENS_CHANGE_SAIDAS_MOTORISTA:
            return {
                ...state,
                saidas: state.saidas.map(
                    (saida, i) => saida.ordemcarga === action.ordemcarga ?
                        { ...saida, codmotorista: action.codmotorista, alterou: 1 }
                        : saida
                )
            }

        case DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEUM:
            return {
                ...state,
                saidas: state.saidas.map(
                    (saida, i) => saida.ordemcarga == action.ordemcarga ?
                        { ...saida, ajudanteum: action.codajudante, alterou: 1 }
                        : saida
                )
            }


        case DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEDOIS:
            return {
                ...state,
                saidas: state.saidas.map(
                    (saida, i) => saida.ordemcarga === action.ordemcarga ?
                        { ...saida, ajudantedois: action.codajudante, alterou: 1 }
                        : saida
                )
            }


        case DESPESASDEVIAGENS_CHANGE_SAIDAS_DTSAIDA:
            return {
                ...state,
                saidas: state.saidas.map(
                    (saida, i) => saida.ordemcarga === action.ordemcarga ?
                        { ...saida, dtprevsaida: action.payload, alterou: 1 }
                        : saida
                )
            }

        case DESPESASDEVIAGENS_CHANGE_SAIDAS_ALTEROU:
            return {
                ...state,
                saidas: state.saidas.map(
                    (saida, i) => saida.ordemcarga === action.ordemcarga ?
                        { ...saida, ajudanteum: "", ajudantedois: "", alterou: 0 }
                        : saida
                )
            }


        case DESPESASDEVIAGENS_CHANGE_SAIDAS_REMOVE:
            return { ...state, saidas: [...state.saidas.filter(c => action.ordemcarga !== c.ordemcarga)] }

        case DESPESASDEVIAGENS_CHANGE_SAIDAS_SAVING:
            return {
                ...state,
                saidas: state.saidas.map(
                    (saida, i) => saida.ordemcarga === action.ordemcarga ?
                        { ...saida, issaving: action.issaving }
                        : saida
                )
            }



        case DESPESASDEVIAGENS_LIST_CHEGADAS:
            return { ...state, chegadas: action.payload }

        case DESPESASDEVIAGENS_LOADER_CHEGADAS:
            return { ...state, loaderDespesasDeViagensChegada: action.payload }

        case DESPESASDEVIAGENS_CHANGE_CHEGADAS_DTCHEGADA:
            return {
                ...state,
                chegadas: state.chegadas.map(
                    (chegada, i) => chegada.ordemcarga === action.ordemcarga ?
                        { ...chegada, dtprevchegada: action.payload }
                        : chegada
                )
            }

        case DESPESASDEVIAGENS_LIST_VIAGEMDESPESAS:
            return { ...state, viagemdespesas: action.payload }


        case DESPESASDEVIAGENS_CHANGE_CHEGADAS:

            //return { ...state, TarefaEntity: { ...state.TarefaEntity, [action.field]: action.payload } }
            return {
                ...state,
                chegadas: state.chegadas.map(
                    (chegada, i) => chegada.ordemcarga === action.ordemcarga ?
                        { ...chegada, [action.field]: action.payload }
                        : chegada
                )
            }

        case DESPESASDEVIAGENS_INSERT_CHEGADAS:
            return { ...state, despesas: action.payload }

        case DESPESASDEVIAGENS_ADICIONAL_INSERT_CHEGADAS:
            return { ...state, despesasAdicional: action.payload }


        case DESPESASDEVIAGENS_CHANGE_CHEGADAS_REMOVE:
            return { ...state, chegadas: [...state.chegadas.filter(c => action.ordemcarga !== c.ordemcarga)] }

        case DESPESASDEVIAGENS_CHANGE_CHEGADAS_SAVING:
            return {
                ...state,
                chegadas: state.chegadas.map(
                    (chegada, i) => chegada.ordemcarga === action.ordemcarga ?
                        { ...chegada, issaving: action.issaving }
                        : chegada
                )
            }

        case DESPESASDEVIAGENS_LIST_FERIADOS:
            return { ...state, feriados: action.payload }



        case DESPESASDEVIAGENS_LIST_RECIBOS:
            return { ...state, recibos: action.payload }

        case DESPESASDEVIAGENS_LOADER_RECIBOS:
            return { ...state, loaderDespesasDeViagensRecibos: action.payload }

        case DESPESASDEVIAGENS_CHANGEDT_RECIBOS:
            return {
                ...state,
                recibos: state.recibos.map(
                    (recibo, i) => recibo.ordemcarga === action.ordemcarga ?
                        { ...recibo, dtreciboimpresso: action.payload }
                        : recibo
                )
            }





        case DESPESASDEVIAGENS_LIST_ASSINATURAS:
            return { ...state, assinaturas: action.payload }

        case DESPESASDEVIAGENS_LOADER_ASSINATURAS:
            return { ...state, loaderDespesasDeViagensAssinaturas: action.payload }

        case DESPESASDEVIAGENS_CHANGEDT_ASSINATURAS:
            if (action.tipodado === 'motorista') {
                return {
                    ...state,
                    assinaturas: state.assinaturas.map(
                        (assinatura, i) => assinatura.ordemcarga === action.ordemcarga ?
                            { ...assinatura, motoristadespesas: [...assinatura.motoristadespesas.filter(d => d.dia !== action.data)] }
                            : assinatura
                    )
                }
            } else if (action.tipodado === 'ajudante') {
                return {
                    ...state,
                    assinaturas: state.assinaturas.map(
                        (assinatura, i) => assinatura.ordemcarga === action.ordemcarga ?
                            { ...assinatura, ajudantedespesas: [...assinatura.ajudantedespesas.filter(d => d.dia !== action.data)] }
                            : assinatura
                    )
                }

            } else if (action.tipodado === 'ajudanteduplo') {
                return {
                    ...state,
                    assinaturas: state.assinaturas.map(
                        (assinatura, i) => assinatura.ordemcarga === action.ordemcarga ?
                            { ...assinatura, ajudanteduplodespesas: [...assinatura.ajudanteduplodespesas.filter(d => d.dia !== action.data)] }
                            : assinatura
                    )
                }

            }

        case DESPESASDEVIAGENS_LIST_ASSINATURAS_RH:
            return { ...state, usersrh: action.payload }



        case DESPESASDEVIAGENS_LIST_FINANCEIROAPROCESSAR:
            return { ...state, financeiroaprocessar: action.payload }

        case DESPESASDEVIAGENS_LOADER_FINANCEIROAPROCESSAR:
            return { ...state, loaderDespesasDeViagensFinanceiroAProcessar: action.payload }


        case DESPESASDEVIAGENS_LIST_FINANCEIROGERADO:
            return { ...state, financeirogerado: action.payload }

        case DESPESASDEVIAGENS_LOADER_FINANCEIROGERADO:
            return { ...state, loaderDespesasDeViagensFinanceiroGerado: action.payload }

        case DESPESASDEVIAGENS_LIST_FINANCEIROGERADO_USERSFIN:
            return { ...state, usersfin: action.payload }

        case DESPESASDEVIAGENS_CHANGE_FINANCEIROGERADO_SAVING:

            return {
                ...state,
                financeirogerado: state.financeirogerado.map(
                    (financeiro, i) => financeiro.motorista === action.motorista ?
                        { ...financeiro, issaving: action.issaving }
                        : financeiro
                )
            }




        case DESPESASDEVIAGENS_SAVING_AJUDANTE:
            return {
                ...state,
                ajudante: state.ajudante.map(
                    (ajudante) => ajudante.idviagemajudante === action.idviagemajudante ?
                        { ...ajudante, issaving: action.issaving }
                        : ajudante
                )
            }

        case DESPESASDEVIAGENS_EDITING_AJUDANTE:
            return {
                ...state,
                ajudante: state.ajudante.map(
                    (ajudante) => ajudante.idviagemajudante === action.payload ?
                        { ...ajudante, isediting: action.isediting }
                        : ajudante
                )
            }
        case DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_NOME:
            return {
                ...state,
                ajudante: state.ajudante.map(
                    (ajudante, i) => ajudante.idviagemajudante === action.payload ?
                        { ...ajudante, viagemajudante: action.viagemajudante }
                        : ajudante
                )
            }

        case DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_ATIVO:
            return {
                ...state,
                ajudante: state.ajudante.map(
                    (ajudante, i) => ajudante.idviagemajudante === action.payload ?
                        { ...ajudante, ativo: action.ativo }
                        : ajudante
                )
            }

        case DESPESASDEVIAGENS_CHANGE_AJUDANTE_SAVING:
            return {
                ...state,
                ajudante: state.ajudante.map(
                    (ajudante, i) => ajudante.idviagemajudante === action.payload ?
                        { ...ajudante, issaving: action.issaving }
                        : ajudante
                )
            }







        case DESPESASDEVIAGENS_REMOVE_FINANCEIROGERADO:
            return { ...state, financeirogerado: [...state.financeirogerado.filter(c => action.nufin !== c.nufin)] }

        case DESPESASDEVIAGENS_REMOVE_FINANCEIROGERADO_OC:
            return { ...state, financeirogerado: [...state.financeirogerado.filter(c => action.motorista !== c.motorista)] }



        case DESPESASDEVIAGENS_CHANGE_ISCANCELING:
            return { ...state, iscanceling: action.payload }

        case DESPESASDEVIAGENS_REMOVE_ORDEMCARGA:
            return { ...state, recibos: [...state.recibos.filter(d => d.idviagem !== action.payload)] }



        //busca----------------
        case DESPESASDEVIAGENS_UPDATE_BUSCA:
            return { ...state, RJBuscaEntity: { ...state.RJBuscaEntity, [action.field]: action.payload } }

        case DESPESASDEVIAGENS_VALIDACAO_BUSCA_ADD:
            return { ...state, validacao: [...state.validacao, action.payload] }

        case DESPESASDEVIAGENS_VALIDACAO_BUSCA_REMOVE:
            return { ...state, validacao: [] }

        case DESPESASDEVIAGENS_ISSEARCHING_BUSCA:
            return { ...state, isSearching: action.payload }

        case DESPESASDEVIAGENS_LIST_SEARCH:
            return { ...state, search: action.payload }
        //busca --------------------




        case DESPESASDEVIAGENS_LIST_MESMODIA:
            return { ...state, mesmodia: action.payload }

        case DESPESASDEVIAGENS_ISREMOVING_MESMODIA:
            return {
                ...state, mesmodia: state.mesmodia.map(
                    (ordem, i) => ordem.idviagem === action.payload ?
                        { ...ordem, isremoving: action.isremoving }
                        : ordem
                )
            }

        case DESPESASDEVIAGENS_REMOVE_MESMODIA:
            return { ...state, mesmodia: [...state.mesmodia.filter(d => d.idviagem !== action.payload)] }

        case DESPESASDEVIAGENS_LIST_ADICIONAL:
            return { ...state, adicionallist: action.payload }

        default:
            return state;
    }
}