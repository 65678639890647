import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Label, Menu, Tab, Dropdown, Grid, Breadcrumb } from 'semantic-ui-react'
import { Icon } from 'semantic-ui-react'
import ListEmRota from './ListEmRota';
import ListEntregues from './ListEntregues';
import ListAuto from './ListAuto';
import ListNaoPagos from './ListNaoPagos';
import ListEmAndamento from './ListEmAndamento';
import ListFaturadosSemRota from './ListFaturadosSemRota';
import ListEmTransportadora from './ListEmTransportadora';

import ListDevolucao from './ListDevolucao';

import ListASair from './ListASair';
import styled from 'styled-components';



import {
  ordemCargaListNaoEntregueAction
  , ordemCargaListEntregueAction
  , ordemCargaInsertCHKNaoEntregueAction
  , ordemCargaRemoveCHKEntregueAction
  , ordemCargaUpdateCHKNaoEntregueAction
  , ordemCargaSaveNaoEntregueAction
  , ordemCargaListPedidosAction
  , ordemCargaPedidoModalUpdateAction
  , ordemCargaPedidoUpdateAction
  , startTimerAction
  , stopTimerAction
  , ordemCargaListMotivosAction
  , ordemCargaSaveMotivoNaoEntregueAction
  //EM TRANSPORTADORA------------------------------
  , ordemCargaSaveEmTransportadoraAction
  , ordemCargaSaveMotivoEmTransportadoraAction
  , ordemCargaInsertCHKEmTransportadoraAction
  , ordemCargaUpdateCHKEmTransportadoraAction
  , ordemCargaRemoveCHKEmTransportadoraAction
  , ordemCargaPedidoEmTransportadoraModalUpdateAction
  , ordemCargaPedidoEmTransportadoraUpdateAction
  , ordemCargaListEmTransportadoraAction
  , ordemCargaUpdateDevolucaoAction
  , ordemCargaListDevolucaoAction
  , ordemCargaUpdateDevolucaoFinalizaAction
  //-----------------------------------------------
  , ordemCargaUpdateDevolucaoTranspAction
  , ordemCargaUpdateDevolucaoEntreguesAction
  , ordemCargaPedidoModalDEVUpdateAction
  , ordemCargaPedidoDEVUpdateAction
  , ordemCargaEmAndamentoEntregueAction

  //A Sair-----------------------------------------
  , ordemCargaListASairAction
  , ordemCargaInsertCHKEmRotaAction
  , ordemCargaRemoveCHKEmRotaAction
  , ordemCargaUpdateCHKEmRotaAction
  , ordemCargaSaveEmRotaAction
  //-----------------------------------------------

  , ordemCargaUpdateNaoPagosFaturadosFreteAction
  , ordemCargaUpdateNaoPagosFaturadosFreteTextoAction
  , ordemCargaSaveNaoPagosFaturadosFrete
  , ordemCargaNaoPagosLigarAction
  , ordemCargaEmAndamentoLigarAction


  //A Sair Frete
  , handleASairFreteOpenClose
  , handleASairFreteCheck
  , handleASairFreteSave

  //Em Rota frete
  , handleEmRotaFreteOpenClose
  , handleEmRotaFreteCheck
  , handleEmRotaFreteSave

  //Em Transportadora frete
  , handleEmTransportadoraFreteOpenClose
  , handleEmTransportadoraFreteCheck
  , handleEmTransportadoraFreteSave

  //A SAIR MOTIVO N SAIU
  , ordemCargaPedidoModalMotivoNSaiuUpdateAction
  , ordemCargaPedidoMotivoNSaiuUpdateAction
  , ordemCargaPedidoMotivoNSaiuSaveAction
  , ordemCargaUpdateEmRotaParaASairAction

  , ordemCargaEmRotaUpdatePrazosAction
  , ordemCargaEmRotaSavePrazosAction

  , ordemCargaEmRotaListAppEntregasAction
  , ordemCargaEmRotaModalAppEntregasAction

  , ordemCargaEmTransportadoraUpdatePrazosAction
  , ordemCargaEmTransportadoraSavePrazosAction

  , ordemCargaEmDevolucaoUpdatePrazosAction
  , ordemCargaEmDevolucaoSavePrazosAction
  , ordemCargaListVendedoresAction
  , ordemCargaCheckVendedoresAction

  , ordemCargaListAutoAction
  , ordemCargaUpdateAutoAction

  , ordemCargaNaoPagosMODALLigarAction
  , ordemCargaEmAndamentoMODALLigarAction
  , ordemCargaNaoPagosCOMPLEMENTOLigarAction
  , ordemCargaEmAndamentoCOMPLEMENTOLigarAction

} from '../../actions/OrdemCargaActions';


const StyledMenuITem = styled(Menu.Item)`
&&& 
 {
    font-size: .8em !important;
}

`


const VendedoresDropdown = styled(Dropdown)`
  &&& {
    div{
        font-size:10px !important;
        width:180px !important;
    },
    span{
        font-size:10px !important;
        width:180px !important;
    }

   }`

//import { loginValidAction } from '../../actions/LoginActions';

class OrdemCarga extends Component {

  constructor(props) {
    super(props);




    this.handleLoader1 = this.handleLoader1.bind(this);
    this.handleLoader2 = this.handleLoader2.bind(this);
    this.handlePedidoModal = this.handlePedidoModal.bind(this);
    this.handlePedido = this.handlePedido.bind(this);

    this.handleCHKInsertNaoEntregue = this.handleCHKInsertNaoEntregue.bind(this);
    this.handleCHKRemoveNaoEntregue = this.handleCHKRemoveNaoEntregue.bind(this);
    this.handleCHKUpdateNaoEntregue = this.handleCHKUpdateNaoEntregue.bind(this);

    this.handleCHKInsertEmRota = this.handleCHKInsertEmRota.bind(this);
    this.handleCHKRemoveEmRota = this.handleCHKRemoveEmRota.bind(this);
    this.handleCHKUpdateEmRota = this.handleCHKUpdateEmRota.bind(this);

    this.handleSavePedidosNaoEntregue = this.handleSavePedidosNaoEntregue.bind(this);
    this.controlaTimer = this.controlaTimer.bind(this);

    //EM TRANSPORTADORA-------------------------------------
    this.handleCHKInsertEmTransportadora = this.handleCHKInsertEmTransportadora.bind(this);
    this.handleCHKRemoveEmTransportadora = this.handleCHKRemoveEmTransportadora.bind(this);
    this.handleCHKUpdateEmTransportadora = this.handleCHKUpdateEmTransportadora.bind(this);

    this.handleSavePedidosEmTransportadora = this.handleSavePedidosEmTransportadora.bind(this);
    this.handlePedidoEmTransportadoraModal = this.handlePedidoEmTransportadoraModal.bind(this);
    this.handlePedidoEmTransportadora = this.handlePedidoEmTransportadora.bind(this);




    this.handlePedidoDEVModal = this.handlePedidoDEVModal.bind(this);
    this.handlePedidoDEV = this.handlePedidoDEV.bind(this);

    //this.handleSavePedidosEmRota = this.handleSavePedidosEmRota.bind(this);


    //a sair motivo
    this.handlePedidoModalMotivoNSaiu = this.handlePedidoModalMotivoNSaiu.bind(this)
    this.handlePedidoMotivoNSaiu = this.handlePedidoMotivoNSaiu.bind(this)



    //------------------------------------------------------
  }

  state = {
    date: new Date(),
    loader1: true,
    loader2: true,
  }


  componentDidMount() {
    //Verificando permissão de funcionalidade do MENU-------------------
    let { menu, history } = this.props;
    if (menu.filter(m => m.menuid === 2 && m.status === 1).length === 0) { history.push(`/Home`) }
    //------------------------------------------------------------------

    this.props.ordemCargaListVendedoresAction().then(d => {
      // alert('carrregar')


      //Não Pagos & Em Andamento
      this.props.ordemCargaListPedidosAction().then(data => { })

      //A Sair
      this.props.ordemCargaListASairAction().then(data => { })

      //Entregues
      this.props.ordemCargaListEntregueAction().then(data => { this.handleLoader2(false) })

      //Em Rota
      this.props.ordemCargaListNaoEntregueAction().then(data => { this.handleLoader1(false) })

      //Transportadora
      this.props.ordemCargaListEmTransportadoraAction().then(data => { })

      //Devolucao
      this.props.ordemCargaListDevolucaoAction().then(data => { })

      //Automático
      this.props.ordemCargaListAutoAction().then(data=>{});


    })

    /*  
    
    this.props.ordemCargaListNaoEntregueAction().then(data => { this.handleLoader1(false) })
    this.props.ordemCargaListEntregueAction().then(data => { this.handleLoader2(false) })
    this.props.ordemCargaListMotivosAction()
    this.props.ordemCargaListEmTransportadoraAction().then(data => { })
    this.props.ordemCargaListDevolucaoAction().then(data => { })
    this.props.ordemCargaListASairAction().then(data => { })
    this.props.startTimerAction()
    */

  }

  controlaTimer() {
    let encontrouPedidoAlterado = false;

    //Não Entregues - Em Rota
    for (let c of this.props.cargasNaoEntregue) {
      for (let p of c.pedidos) {
        if (p.alterado) {
          encontrouPedidoAlterado = true
          break;
        }
      }
    }

    //Em Transpotadora
    for (let c of this.props.cargasEmTransportadora) {
      for (let p of c.pedidos) {
        if (p.alterado) {
          encontrouPedidoAlterado = true
          break;
        }
      }
    }


    if (this.props.chkNaoEntregue.length > 0 || this.props.chkEmTransportadora.length > 0 || encontrouPedidoAlterado) {
      this.props.stopTimerAction()
      //console.log('##parou')
    } else {
      //console.log('##ligou')
      this.props.startTimerAction()
    }

  }



  handlePedidoModal(pedido, open, isbtnSalvar) {

    //se salvou

    if (!open && isbtnSalvar) {
      pedido.ad_tracknusuari = this.props.login
      this.props.ordemCargaSaveMotivoNaoEntregueAction([pedido]).then(data => {
        for (let p of data) {
          if (p.status === 'OK') {
            pedido.alterado = false
            this.props.ordemCargaPedidoModalUpdateAction(pedido, open)
            this.controlaTimer();
          } else {
            this.props.ordemCargaPedidoModalUpdateAction(pedido, open)
          }
        }

      })

    } else {
      this.props.ordemCargaPedidoModalUpdateAction(pedido, open)
      this.controlaTimer();
    }
  }

  handlePedidoDEVModal(pedido, open, isbtnSalvar) {

    //se salvou

    if (!open && isbtnSalvar) {
      pedido.ad_tracknusuari = this.props.login
      this.props.ordemCargaSaveMotivoNaoEntregueAction([pedido]).then(data => {
        for (let p of data) {
          if (p.status === 'OK') {
            pedido.alterado = false
            this.props.ordemCargaPedidoModalDEVUpdateAction(pedido, open)
            this.controlaTimer();
          } else {
            this.props.ordemCargaPedidoModalDEVUpdateAction(pedido, open)
          }
        }

      })

    } else {
      this.props.ordemCargaPedidoModalDEVUpdateAction(pedido, open)
      this.controlaTimer();
    }
  }

  handlePedido(pedido) {
    this.controlaTimer();
    pedido.ad_tracknusuari = this.props.login
    this.props.ordemCargaPedidoUpdateAction(pedido)
  }

  handlePedidoDEV(pedido) {
    this.controlaTimer();
    pedido.ad_tracknusuari = this.props.login
    this.props.ordemCargaPedidoDEVUpdateAction(pedido)
  }



  handleCHKInsertNaoEntregue(pedido) {
    pedido.ad_trackusuario = this.props.login;
    this.props.ordemCargaInsertCHKNaoEntregueAction(pedido).then(data => {
      this.controlaTimer()
    });

  }

  handleCHKRemoveNaoEntregue(pedido) {
    this.props.ordemCargaRemoveCHKEntregueAction(pedido).then(data => {
      this.controlaTimer()
    })
  }

  handleCHKUpdateNaoEntregue(pedido) {
    pedido.ad_trackusuario = this.props.login;
    this.props.ordemCargaUpdateCHKNaoEntregueAction(pedido);
  }






  handleCHKInsertEmRota(pedido) {
    pedido.ad_trackusuario = this.props.login;
    this.props.ordemCargaInsertCHKEmRotaAction(pedido).then(data => {
      //this.controlaTimer()
    });

  }

  handleCHKRemoveEmRota(pedido) {
    this.props.ordemCargaRemoveCHKEmRotaAction(pedido).then(data => {
      //this.controlaTimer()
    })
  }

  handleCHKUpdateEmRota(pedido) {
    pedido.ad_trackusuario = this.props.login;
    this.props.ordemCargaUpdateCHKEmRotaAction(pedido);
  }





  handleLoader1(valor) {
    this.setState({ loader1: valor });
  }

  handleLoader2(valor) {
    this.setState({ loader2: valor });
  }


  handleSavePedidosNaoEntregue() {

    let pedidos = this.props.chkNaoEntregue

    for (let p of pedidos) {
      p.ad_tracknusuari = this.props.login
    }

    this.props.ordemCargaSaveNaoEntregueAction(pedidos).then(data => {
      this.controlaTimer()
    })

  }




  //EM TRANSPORTADORA-----------------------------------------------------------
  handleCHKInsertEmTransportadora(pedido) {
    pedido.ad_trackusuario = this.props.login;
    this.props.ordemCargaInsertCHKEmTransportadoraAction(pedido).then(data => {
      this.controlaTimer()
    });
  }

  handleCHKRemoveEmTransportadora(pedido) {
    this.props.ordemCargaRemoveCHKEmTransportadoraAction(pedido).then(data => {
      this.controlaTimer()
    })
  }

  handleCHKUpdateEmTransportadora(pedido) {
    pedido.ad_trackusuario = this.props.login;
    this.props.ordemCargaUpdateCHKEmTransportadoraAction(pedido);
  }

  handleSavePedidosEmTransportadora() {
    this.props.ordemCargaSaveEmTransportadoraAction(this.props.chkEmTransportadora).then(data => {
      this.controlaTimer()
    })
  }

  handlePedidoEmTransportadoraModal(pedido, open, isbtnSalvar) {

    //se salvou

    if (!open && isbtnSalvar) {
      pedido.ad_tracknusuari = this.props.login
      this.props.ordemCargaSaveMotivoEmTransportadoraAction([pedido]).then(data => {
        for (let p of data) {
          if (p.status === 'OK') {
            pedido.alterado = false
            this.props.ordemCargaPedidoEmTransportadoraModalUpdateAction(pedido, open)
            this.controlaTimer();
          } else {
            this.props.ordemCargaPedidoEmTransportadoraModalUpdateAction(pedido, open)
          }
        }
      })

    } else {
      this.props.ordemCargaPedidoEmTransportadoraModalUpdateAction(pedido, open)
      this.controlaTimer();
    }
  }

  handlePedidoEmTransportadora(pedido) {

    this.controlaTimer();
    pedido.ad_tracknusuari = this.props.login
    this.props.ordemCargaPedidoEmTransportadoraUpdateAction(pedido)
  }
  //----------------------------------------------------------------------------











  //a sair - motivo não saiu----------------------------------------------------------
  handlePedidoModalMotivoNSaiu(pedido, open, isbtnSalvar) {

    /*alert(`handlePedidoModalMotivoNSaiu
    open: ${open}
    isbtnSalvar: ${isbtnSalvar}
    pedidofbits: ${pedido.pedidofbits}
    `)*/
    //se salvou
    this.props.ordemCargaPedidoModalMotivoNSaiuUpdateAction(pedido, open);
    //this.props.ordemCargaPedidoModalUpdateAction(pedido, open);

  }


  handlePedidoMotivoNSaiu(pedido) {
    pedido.ad_tracknusuarinsaiu = this.props.login
    this.props.ordemCargaPedidoMotivoNSaiuUpdateAction(pedido)
  }


  handlePedidoMotivoNSaiu(pedido) {
    //alert('handlePedidoMotivoNSaiu')

    pedido.ad_tracknusuarinsaiu = this.props.login
    this.props.ordemCargaPedidoUpdateAction(pedido)


    /*pedido.ad_tracknusuarinsaiu = this.props.login
    this.props.ordemCargaPedidoUpdateAction(pedido)
    //this.props.ordemCargaPedidoUpdateAction(pedido)
    */
  }
  //------------------------------------------------------------------------------






  handleDropCodVend = (e, { value }) => {


    this.props.ordemCargaCheckVendedoresAction(value.toString()).then(d => {


      //Não Pagos & Em Andamento
      this.props.ordemCargaListPedidosAction().then(data => { })

      //FINALIZADOS
      this.props.ordemCargaListEntregueAction().then(data => { this.handleLoader2(false) })

      //A Sair
      this.props.ordemCargaListASairAction().then(data => { })


      //Em Rota
      this.props.ordemCargaListNaoEntregueAction().then(data => { this.handleLoader1(false) })

      //Atualiza Transportadora
      this.props.ordemCargaListEmTransportadoraAction().then(data => { })

      //Devolucao
      this.props.ordemCargaListDevolucaoAction().then(data => { })

      //Automático
      this.props.ordemCargaListAutoAction().then(data=>{});


    })





  }


  filtracargas(cargas, vendedores) {
    let dtcargas = []

    for (let c of cargas) {
      let pedidos = [...c.pedidos.filter(d => vendedores.includes(d.codvend))]

      if (pedidos.length > 0) {
        let novacarga = c;
        novacarga.pedidos = pedidos
        dtcargas.push(novacarga)
      }
    }

    return dtcargas

  }














  render() {
 //teste deploy

    let {

      //, cargasEntregue
      cargasNaoEntregue
      , cargasNaoEntregueQtde
      , cargasEntregueQtde
      , chkNaoEntregue
      , chkEmRota
      , loaderNaoEntregue
      , loaderEntregue
      , handleCHKInsertNaoEntregue, handleCHKRemoveNaoEntregue, handleCHKUpdateNaoEntregue
      , handleSavePedidosNaoEntregue
      , logScreen

      //Em Transportadora-----------------------
      , cargasEmTransportadora
      , cargasEmTransportadoraQtde
      , loaderEmTransportadora
      , chkEmTransportadora
      , logEmTransportadora
      //----------------------------------------


      , loaderPedidosNaoPagos
      , pedidosNaoPagosQtde

      //, pedidosPagosNaoFaturados
      , loaderPedidosPagosNaoFaturados
      , pedidosPagosNaoFaturadosQtde



      , pedidosFaturadosSemRota
      , loaderPedidosFaturadosSemRota
      , pedidosFaturadosSemRotaQtde

      , history
      , motivos

      , devolucao
      , devolucaoqtde
      , loaderDevolucao

      , cargasASair
      , cargasASairQtde
      , loaderASair

      , vendedores
      , vendedorselecionado
      
      , pedidosNaoPagos
      , pedidosPagosNaoFaturados
      , cargasEntregue

      , cargasAuto
      , cargasAutoQtde
      , loaderAuto

    } = this.props;



    let vendedorarr = []
    if (vendedorselecionado != "") {
      vendedorarr = vendedorselecionado.split(',').map(function (item) { return parseInt(item, 10); });
    }


    /*let pedidosNaoPagos = this.props.pedidosNaoPagos.filter(d => vendedorarr.includes(d.codvend))
    let pedidosPagosNaoFaturados = this.props.pedidosPagosNaoFaturados.filter(d => vendedorarr.includes(d.codvend))
    let cargasEntregue = this.props.cargasEntregue.filter(d => vendedorarr.includes(d.codvend))*/

    /*let cargasASairQtde =0
    let cargasASair = this.props.cargasASair.filter((carga)=>{
      let pedidos = [...carga.pedidos.filter(p => vendedorarr.includes(p.codvend))];
      carga.pedidos = pedidos
      cargasASairQtde = cargasASairQtde + carga.pedidos.length;
      if (pedidos.length > 0) {
       return carga
      }else{
        return
      }
    })*/




    /*let cargasASairQtde =0
    let cargasASair = this.filtracargas(this.props.cargasASair, vendedorarr);
    cargasASair.map(carga => (cargasASairQtde+= carga.pedidos.length))
    */



    /*
    
    var young = people.filter((obj) => {
      var flag = false;
      Object.values(obj).forEach((val) => {
        if(String(val).indexOf("J") > -1) {
          flag = true;
          return;
        }    
      });
      if(flag) return obj;
    });
    */









    let panes = [



      {
        menuItem: <StyledMenuITem key='TAB3'>Não Pagos<Label color='yellow'>{
          //pedidosNaoPagosQtde
          pedidosNaoPagos.length
        }</Label></StyledMenuITem>,
        render: () => <ListNaoPagos
          dataList={pedidosNaoPagos}
          loader={loaderPedidosNaoPagos}
          ordemCargaNaoPagosLigarAction={this.props.ordemCargaNaoPagosLigarAction}
          ordemCargaNaoPagosCOMPLEMENTOLigarAction={this.props.ordemCargaNaoPagosCOMPLEMENTOLigarAction}
          ordemCargaNaoPagosMODALLigarAction={this.props.ordemCargaNaoPagosMODALLigarAction}
          
        />,
      },
      {
        menuItem: <StyledMenuITem key='TAB4'>Em Andamento<Label color='orange'>
          {
            //pedidosPagosNaoFaturadosQtde
            pedidosPagosNaoFaturados.length
          }
        </Label></StyledMenuITem>,
        render: () => <ListEmAndamento
          dataList={pedidosPagosNaoFaturados}
          loader={loaderPedidosPagosNaoFaturados}
          ordemCargaEmAndamentoEntregueAction={this.props.ordemCargaEmAndamentoEntregueAction}
          login={this.props.login}
          ordemCargaUpdateNaoPagosFaturadosFreteAction={this.props.ordemCargaUpdateNaoPagosFaturadosFreteAction}
          ordemCargaUpdateNaoPagosFaturadosFreteTextoAction={this.props.ordemCargaUpdateNaoPagosFaturadosFreteTextoAction}
          ordemCargaSaveNaoPagosFaturadosFrete={this.props.ordemCargaSaveNaoPagosFaturadosFrete}
          ordemCargaEmAndamentoLigarAction={this.props.ordemCargaEmAndamentoLigarAction}
          ordemCargaEmAndamentoCOMPLEMENTOLigarAction={this.props.ordemCargaEmAndamentoCOMPLEMENTOLigarAction}
          ordemCargaEmAndamentoMODALLigarAction={this.props.ordemCargaEmAndamentoMODALLigarAction}
        />,
      },

      /*
      //cancelado pois o conteúdo foi adicionado junto no componente de ListEmAndamento
      {
        menuItem: <Menu.Item key='TAB5'><Icon name='compass' />Aguardando <Label color='red'>{pedidosFaturadosSemRotaQtde}</Label></Menu.Item>,
        render: () => <ListFaturadosSemRota
          dataList={pedidosFaturadosSemRota}
          loader={loaderPedidosFaturadosSemRota}
        />,
      },
    
      */

      {
        menuItem: <StyledMenuITem key='TAB1sair'><Icon name='truck' />A Sair<Label color='red'>{cargasASairQtde}</Label></StyledMenuITem>,
        render: () => <ListASair
          cargas={cargasASair}
          loader={loaderASair}
          handleCHKInsertEmRota={this.handleCHKInsertEmRota}
          handleCHKRemoveEmRota={this.handleCHKRemoveEmRota}
          handleCHKUpdateEmRota={this.handleCHKUpdateEmRota}
          chkEmRota={chkEmRota}
          motivos={motivos}
          //handleSavePedidosEmRota={this.handleSavePedidosEmRota}
          handleSavePedidosEmRota={this.props.ordemCargaSaveEmRotaAction}
          login={this.props.login}

          //frete
          handleASairFreteOpenClose={this.props.handleASairFreteOpenClose}
          handleASairFreteCheck={this.props.handleASairFreteCheck}
          handleASairFreteSave={this.props.handleASairFreteSave}

          //motivonsaiu
          handlePedidoModalMotivoNSaiu={this.handlePedidoModalMotivoNSaiu}
          handlePedidoMotivoNSaiu={this.handlePedidoMotivoNSaiu}
          handleSavePedidosNSaiu={this.props.ordemCargaPedidoMotivoNSaiuSaveAction}
          handleDevolucao={this.props.ordemCargaUpdateDevolucaoAction}

        />,
      },




      {
        menuItem: <StyledMenuITem key='TAB1'><Icon name='truck' />Em Rota<Label color='grey'>{cargasNaoEntregueQtde}</Label></StyledMenuITem>,
        render: () => <ListEmRota
          cargas={cargasNaoEntregue}
          loader={loaderNaoEntregue}
          handleCHKInsertNaoEntregue={this.handleCHKInsertNaoEntregue}
          handleCHKRemoveNaoEntregue={this.handleCHKRemoveNaoEntregue}
          handleCHKUpdateNaoEntregue={this.handleCHKUpdateNaoEntregue}
          chkNaoEntregue={chkNaoEntregue}
          handleSavePedidosNaoEntregue={this.handleSavePedidosNaoEntregue}
          logScreen={logScreen}
          handlePedidoModal={this.handlePedidoModal} //
          handlePedido={this.handlePedido} //
          motivos={motivos} //
          handleDevolucao={this.props.ordemCargaUpdateDevolucaoAction}
          handleEmRotaParaDevolucao={this.props.ordemCargaUpdateDevolucaoAction}//problema

          //frete
          handleEmRotaFreteOpenClose={this.props.handleEmRotaFreteOpenClose}
          handleEmRotaFreteCheck={this.props.handleEmRotaFreteCheck}
          handleEmRotaFreteSave={this.props.handleEmRotaFreteSave}

          ordemCargaEmRotaUpdatePrazosAction={this.props.ordemCargaEmRotaUpdatePrazosAction}
          ordemCargaEmRotaSavePrazosAction={this.props.ordemCargaEmRotaSavePrazosAction}
          login={this.props.login}

          appentrega={this.props.appentrega}
          loaderappentrega={this.props.loaderappentrega}
          appentregamodal={this.props.appentregamodal}
          ordemCargaEmRotaListAppEntregasAction={this.props.ordemCargaEmRotaListAppEntregasAction}
          ordemCargaEmRotaModalAppEntregasAction={this.props.ordemCargaEmRotaModalAppEntregasAction}





        />,
      },

      {
        menuItem: <StyledMenuITem key='TAB6'><Icon name='truck' />Transportadora<Label color='blue'>{cargasEmTransportadoraQtde}</Label></StyledMenuITem>,
        render: () => <ListEmTransportadora
          cargas={cargasEmTransportadora} //ok
          loader={loaderEmTransportadora} //ok
          handleCHKInsertEmTransportadora={this.handleCHKInsertEmTransportadora}//ok
          handleCHKRemoveEmTransportadora={this.handleCHKRemoveEmTransportadora}//ok
          handleCHKUpdateEmTransportadora={this.handleCHKUpdateEmTransportadora}//ok
          chkEmTransportadora={chkEmTransportadora} //ok
          handleSavePedidosEmTransportadora={this.handleSavePedidosEmTransportadora}//ok
          logScreen={logEmTransportadora} //ok
          handlePedidoModal={this.handlePedidoEmTransportadoraModal}//ok
          handlePedido={this.handlePedidoEmTransportadora}//ok
          motivos={motivos}//ok
          handleDevolucao={this.props.ordemCargaUpdateDevolucaoTranspAction}

          //frete
          handleEmTransportadoraFreteOpenClose={this.props.handleEmTransportadoraFreteOpenClose}
          handleEmTransportadoraFreteCheck={this.props.handleEmTransportadoraFreteCheck}
          handleEmTransportadoraFreteSave={this.props.handleEmTransportadoraFreteSave}

          ordemCargaEmTransportadoraUpdatePrazosAction={this.props.ordemCargaEmTransportadoraUpdatePrazosAction}
          ordemCargaEmTransportadoraSavePrazosAction={this.props.ordemCargaEmTransportadoraSavePrazosAction}
          login={this.props.login}


        />,
      },

      {
        menuItem: <StyledMenuITem key='TAB6dev'><Icon name='frown' color="red" />Devolução<Label color='blue'>{devolucaoqtde}</Label></StyledMenuITem>,
        render: () => <ListDevolucao
          cargas={devolucao}
          loader={loaderDevolucao}
          handleDevolucao={this.props.ordemCargaUpdateDevolucaoFinalizaAction}
          motivos={motivos}
          handlePedidoModal={this.handlePedidoDEVModal}
          handlePedido={this.handlePedidoDEV}
          ordemCargaEmDevolucaoUpdatePrazosAction={this.props.ordemCargaEmDevolucaoUpdatePrazosAction}
          ordemCargaEmDevolucaoSavePrazosAction={this.props.ordemCargaEmDevolucaoSavePrazosAction}
          login={this.props.login}
        />,
      },

      {
        menuItem: <StyledMenuITem key='TABentregues'>Finalizados<Label color='green'>
          {
            //cargasEntregueQtde
            cargasEntregue.length
          }

        </Label></StyledMenuITem>,
        render: () => <ListEntregues
          dataList={cargasEntregue}
          loader={loaderEntregue}
          handleDevolucao={this.props.ordemCargaUpdateDevolucaoEntreguesAction}
        />,
      },,
      {
        menuItem: <StyledMenuITem key='TABauto'>Auto <Label color='teal'>{cargasAuto.filter(f=>f.acao==2 && f.dtconferido ==null).length}</Label></StyledMenuITem>,
        render: () => <ListAuto
          dataList={cargasAuto}
          loader={loaderAuto}
          handleAuto={this.props.ordemCargaUpdateAutoAction}//ordemCargaUpdateDevolucaoEntreguesAction
        />,
      }

    ]

    //console.log(vendedorselecionado)


    return (
      <div>

        <Menu stackable size='mini'>
          <Menu.Item>
            <Breadcrumb size='mini'>
              <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
              <Breadcrumb.Divider icon='right chevron' />
              <Breadcrumb.Section active>Painel de Pedidos do E-commerce</Breadcrumb.Section>
            </Breadcrumb>
          </Menu.Item>
        </Menu>


        <Grid columns={1} doubling>
          <Grid.Column>

            <VendedoresDropdown
              options={vendedores}
              selection
              placeholder='Vendedor'
              name='codvend'
              fluid
              value={vendedorselecionado.toString()}
              onChange={this.handleDropCodVend}
              scrolling
              width={500}
            />


            <Tab panes={panes} />
          </Grid.Column>
        </Grid>
      </div>

    )
  }

}

const mapStateToProps = state => (
  {
    menu: state.LoginReducer.menu
    , cargasNaoEntregue: state.OrdemCargaReducer.cargasNaoEntregue
    , cargasEntregue: state.OrdemCargaReducer.cargasEntregue
    , cargasNaoEntregueQtde: state.OrdemCargaReducer.cargasNaoEntregueQtde
    , cargasEntregueQtde: state.OrdemCargaReducer.cargasEntregueQtde
    , chkNaoEntregue: state.OrdemCargaReducer.chkNaoEntregue
    , chkEntregue: state.OrdemCargaReducer.chkEntregue
    , loaderNaoEntregue: state.OrdemCargaReducer.loaderNaoEntregue
    , loaderEntregue: state.OrdemCargaReducer.loaderEntregue
    , logScreen: state.OrdemCargaReducer.logScreen

    //ASair-------------------------------------------
    , chkEmRota: state.OrdemCargaReducer.chkEmRota
    , cargasASair: state.OrdemCargaReducer.cargasASair
    , cargasASairQtde: state.OrdemCargaReducer.cargasASairQtde
    , loaderASair: state.OrdemCargaReducer.loaderASair
    //------------------------------------------------

    //EM TRANSPORTADORA----------------------------------------------------------------
    , cargasEmTransportadora: state.OrdemCargaReducer.cargasEmTransportadora
    , chkEmTransportadora: state.OrdemCargaReducer.chkEmTransportadora
    , cargasEmTransportadoraQtde: state.OrdemCargaReducer.cargasEmTransportadoraQtde
    , loaderEmTransportadora: state.OrdemCargaReducer.loaderEmTransportadora
    , logEmTransportadora: state.OrdemCargaReducer.logEmTransportadora
    //---------------------------------------------------------------------------------

    , vendedores: state.OrdemCargaReducer.vendedores
    , vendedorselecionado: state.OrdemCargaReducer.vendedorselecionado

    , pedidosNaoPagos: state.OrdemCargaReducer.pedidosNaoPagos
    , loaderPedidosNaoPagos: state.OrdemCargaReducer.loaderPedidosNaoPagos
    , pedidosNaoPagosQtde: state.OrdemCargaReducer.pedidosNaoPagosQtde
    , pedidosPagosNaoFaturados: state.OrdemCargaReducer.pedidosPagosNaoFaturados
    , loaderPedidosPagosNaoFaturados: state.OrdemCargaReducer.loaderPedidosPagosNaoFaturados
    , pedidosPagosNaoFaturadosQtde: state.OrdemCargaReducer.pedidosPagosNaoFaturadosQtde
    , pedidosFaturadosSemRota: state.OrdemCargaReducer.pedidosFaturadosSemRota
    , loaderPedidosFaturadosSemRota: state.OrdemCargaReducer.loaderPedidosFaturadosSemRota
    , pedidosFaturadosSemRotaQtde: state.OrdemCargaReducer.pedidosFaturadosSemRotaQtde
    , motivos: state.OrdemCargaReducer.motivos
    , isAuthenticated: state.LoginReducer.isAuthenticated
    , login: state.LoginReducer.login

    , devolucao: state.OrdemCargaReducer.devolucao
    , devolucaoqtde: state.OrdemCargaReducer.devolucaoqtde
    , loaderDevolucao: state.OrdemCargaReducer.loaderDevolucao


    , cargasAuto: state.OrdemCargaReducer.cargasAuto
    , cargasAutoQtde: state.OrdemCargaReducer.cargasAutoQtde
    , loaderAuto: state.OrdemCargaReducer.loaderAuto

    , appentrega: state.OrdemCargaReducer.appentrega
    , loaderappentrega: state.OrdemCargaReducer.loaderappentrega
    , appentregamodal: state.OrdemCargaReducer.appentregamodal

  }
);


export default withRouter(connect(mapStateToProps, {
  ordemCargaListNaoEntregueAction
  , ordemCargaListEntregueAction
  , ordemCargaInsertCHKNaoEntregueAction
  , ordemCargaRemoveCHKEntregueAction
  , ordemCargaUpdateCHKNaoEntregueAction
  , ordemCargaSaveNaoEntregueAction
  , ordemCargaListPedidosAction
  //, loginValidAction
  , ordemCargaPedidoModalUpdateAction
  , ordemCargaPedidoUpdateAction
  , startTimerAction
  , stopTimerAction
  , ordemCargaListMotivosAction
  , ordemCargaSaveMotivoNaoEntregueAction
  //EM TRANSPORTADORA------------------------------
  , ordemCargaSaveEmTransportadoraAction
  , ordemCargaSaveMotivoEmTransportadoraAction
  , ordemCargaInsertCHKEmTransportadoraAction
  , ordemCargaUpdateCHKEmTransportadoraAction
  , ordemCargaRemoveCHKEmTransportadoraAction
  , ordemCargaPedidoEmTransportadoraModalUpdateAction
  , ordemCargaPedidoEmTransportadoraUpdateAction
  , ordemCargaListEmTransportadoraAction
  , ordemCargaUpdateDevolucaoAction
  , ordemCargaListDevolucaoAction
  , ordemCargaUpdateDevolucaoFinalizaAction
  //-----------------------------------------------

  , ordemCargaUpdateDevolucaoTranspAction
  , ordemCargaUpdateDevolucaoEntreguesAction

  , ordemCargaPedidoModalDEVUpdateAction
  , ordemCargaPedidoDEVUpdateAction

  , ordemCargaEmAndamentoEntregueAction

  , ordemCargaListASairAction
  , ordemCargaInsertCHKEmRotaAction
  , ordemCargaRemoveCHKEmRotaAction
  , ordemCargaUpdateCHKEmRotaAction
  , ordemCargaSaveEmRotaAction

  , ordemCargaUpdateNaoPagosFaturadosFreteAction
  , ordemCargaUpdateNaoPagosFaturadosFreteTextoAction
  , ordemCargaSaveNaoPagosFaturadosFrete
  , ordemCargaNaoPagosLigarAction
  , ordemCargaEmAndamentoLigarAction


  //asair motivo nao saiu
  , ordemCargaPedidoModalMotivoNSaiuUpdateAction
  , ordemCargaPedidoMotivoNSaiuUpdateAction
  , ordemCargaPedidoMotivoNSaiuSaveAction


  //ASair frete
  , handleASairFreteOpenClose
  , handleASairFreteCheck
  , handleASairFreteSave

  //Em rota frete
  , handleEmRotaFreteOpenClose
  , handleEmRotaFreteCheck
  , handleEmRotaFreteSave

  //Em Transportadora Frete
  , handleEmTransportadoraFreteOpenClose
  , handleEmTransportadoraFreteCheck
  , handleEmTransportadoraFreteSave

  , ordemCargaUpdateEmRotaParaASairAction

  , ordemCargaEmRotaUpdatePrazosAction
  , ordemCargaEmRotaSavePrazosAction

  , ordemCargaEmRotaListAppEntregasAction
  , ordemCargaEmRotaModalAppEntregasAction

  , ordemCargaEmTransportadoraUpdatePrazosAction
  , ordemCargaEmTransportadoraSavePrazosAction

  , ordemCargaEmDevolucaoUpdatePrazosAction
  , ordemCargaEmDevolucaoSavePrazosAction
  , ordemCargaListVendedoresAction
  , ordemCargaCheckVendedoresAction

  , ordemCargaListAutoAction
  , ordemCargaUpdateAutoAction

  , ordemCargaNaoPagosMODALLigarAction
  , ordemCargaEmAndamentoMODALLigarAction
  , ordemCargaNaoPagosCOMPLEMENTOLigarAction
  , ordemCargaEmAndamentoCOMPLEMENTOLigarAction



}
)(OrdemCarga));
